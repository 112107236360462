.button() {
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  transition: background-color 0.1s;
  border: 0;
  text-align: center;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .sm-hide,
  .xs-hide {
    display: inline;
  }

  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
  }

  &:disabled {
    background-color: var(--disabled) !important;
    color: var(--button-heavy-second-color) !important;
    cursor: unset !important;
    border: none !important;
  }

  &.disabled {
    background-color: var(--disabled) !important;
    color: var(--button-heavy-second-color) !important;
    cursor: unset !important;
    border: none !important;
    pointer-events: none;
  }

  //Same display as disabled but the click event still works
  &.invalid,
  &:invalid {
    background-color: var(--disabled) !important;
    color: var(--button-heavy-second-color) !important;
    cursor: unset !important;
    border: none !important;
  }

  // Used to disable click events temporarily during tutorials without changing the layout
  // It doesn't achieve the same purpose as disabled
  &.inactive {
    pointer-events: none;
  }

  svg {
    margin-right: 5px !important;
  }

  :last-child {
    margin: 0;
  }
  .icon {
    margin-right: 5px !important;
  }
}

.nano-button-small {
  .button;
  font-weight: 700;
  text-align: center;
  line-height: 22px;
  padding: 6px 16px;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  line-height: 1;

  div {
    margin: auto 10px auto auto;
  }

  svg {
    font-size: 16px;
    margin: 0 5px 0 0;
  }

  img {
    height: 18px;
  }

  svg:only-of-type:only-child {
    font-size: 18px;
  }

  .icon {
    margin-right: 5px !important;
  }
}

.nano-button-small-alt {
  .nano-button-small;
  text-transform: initial;
  padding: 12px 22px !important;
}

.nano-button-submit {
  .button;
  font-weight: 700;
  width: auto;
  font-size: 18px;
  padding: 10px 20px;
  margin-top: 50px;
  margin-bottom: 20px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  display: inline;

  i {
    font-size: 18px;
    margin: 0 10px 0 0;
  }

  img {
    height: 18px;
  }
}

.nano-button-micro {
  .button;
  font-weight: 700;
  line-height: 14px;
  width: 60%;
  font-size: 12px;
  padding: 5px;
  margin: 5px 20%;

  i[data-fa-i2] {
    font-size: 12px;
    margin: 0 5px 0 0;
  }

  img {
    height: 12px;
  }
}

.nano-button {
  .button;
  font-weight: bold;
  text-decoration: none;
  display: inline-flex;
  border-radius: 6px;
  padding-left: 17px;
  padding-right: 17px;
  transition: all 0.25s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  align-content: center;
  min-height: 40px;
  line-height: 1;

  div {
    margin: auto;
    padding: 5px 0;
  }

  svg {
    font-size: 16px;
    margin: auto;
  }

  img {
    height: 18px;
  }

  svg:only-of-type:only-child {
    font-size: 18px;
  }

  :first-child {
    margin: auto 10px auto auto;
  }

  :last-child {
    margin: auto auto auto 10px;
  }

  .icon {
    margin-right: 5px !important;
  }

  :only-child {
    margin: auto;
  }
}

.nano-button-big {
  .button;
  font-weight: bold;
  text-decoration: none !important;
  display: inline-flex;
  border-radius: 6px;
  padding-left: 17px;
  padding-right: 17px;
  transition: all 0.25s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  align-content: center;
  min-height: 50px;
  font-size: 20px;
  line-height: 1;
  margin-top: 10px;

  div {
    margin: auto;
    padding: 5px 0;
  }

  svg {
    font-size: 20px;
    margin: auto;
  }

  img {
    height: 24px;
  }

  :last-child {
    margin: auto auto auto 10px;
  }

  :first-child {
    margin: auto 10px auto auto;
  }

  svg:only-of-type:only-child {
    font-size: 24px;
  }

  :only-child {
    margin: auto;
  }

  .icon {
    margin-right: 5px !important;
  }
}

.nano-toggle-button(@colorOn, @colorOff) {
  .button;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);

  &.off {
    background-color: rgba(@colorOff, 0.4);
    color: @colorOff;
    font-weight: bold;
    border: 1px solid @colorOff;

    .toggle-content {
      margin: 0 30px 0 0;
      padding: 8px 10px;
      border-radius: 6px;
      box-shadow: 5px 0 0 rgba(@colorOff, 0.6);
      background-color: var(--default-bg);
      border: 1px solid @colorOff;
    }

    .on-content {
      display: none;
    }
  }

  &.on {
    background-color: rgba(@colorOn, 0.4);
    color: @colorOn;
    font-weight: bold;
    border: 1px solid @colorOn;

    .toggle-content {
      margin: 0 0 0 30px;
      padding: 8px 10px;
      border-radius: 6px;
      box-shadow: -5px 0 0 rgba(@colorOn, 0.6);
      background-color: var(--default-bg);
      border: 1px solid @colorOn;
    }

    .off-content {
      display: none;
    }
  }

  .loading {
    display: none;
    font-size: 20px;
  }

  &.disabled {
    display: flex;

    .loading {
      display: initial;
      margin: auto;
    }

    .toggle-content {
      display: none;
    }
  }
}

.usage-type-button {
  @media (max-width: 992px) {
    margin-top: 15px;
  }
}

.nano-button--heavy(@first-color) {
  background-color: @first-color !important;
  color: var(--button-heavy-second-color) !important;
  border: 1px solid transparent;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);

  &:hover:not(.disabled) {
    background-color: var(--button-heavy-second-color) !important;
    color: @first-color !important;
    text-decoration: none;
  }

  &:focus {
    background-color: var(--button-heavy-second-color);
    color: @first-color;
  }
}

.nano-button--light(@first-color) {
  background-color: var(--button-light-second-color) !important;
  color: @first-color !important;
  border: 1px solid @first-color;

  &:hover:not(.disabled) {
    background-color: @first-color !important;
    color: var(--button-light-second-color) !important;
    text-decoration: none;

    a {
      color: var(--button-light-second-color) !important;
      text-decoration: none;
    }
  }

  &:focus {
    background-color: @first-color;
    color: var(--button-light-second-color);
  }

  a {
    color: @first-color;
  }
}

.--micro {
  padding: 6px 12px;

  svg {
    margin: 0 !important;
  }
}

.excel-button {
  .nano-button--heavy(var(--button-excell));
  .usage-type-button();
}

.download-button {
  .nano-button--light(var(--button-download));
}

//region Constellation user challenges
.uc-waiting-review-button {
  .nano-button--heavy(var(--button-uc-correction));
}

.uc-resend-button {
  .nano-button--light(var(--button-uc-correction));
}

.uc-current-button {
  .nano-button--heavy(var(--button-uc-revision));
}

.uc-private-button {
  .nano-button--heavy(var(--button-uc-failed));
}

.uc-done-button {
  .nano-button--heavy(var(--button-uc-done));
}

//endregion

//region student profile overlay
.uc-new-button {
  .nano-button--heavy(var(--userchallenge-status-new));

  &.selected {
    .nano-button--light(var(--userchallenge-status-new));
  }
}

.uc-construction-button {
  .nano-button--heavy(var(--userchallenge-status-construction));

  &.selected {
    .nano-button--light(var(--userchallenge-status-construction));
  }
}

.uc-correction-button {
  .nano-button--heavy(var(--userchallenge-status-correction));

  &.selected {
    .nano-button--light(var(--userchallenge-status-correction));
  }
}

.uc-revision-button {
  .nano-button--heavy(var(--userchallenge-status-revision));

  &.selected {
    .nano-button--light(var(--userchallenge-status-revision));
  }
}

.uc-done-button {
  .nano-button--heavy(var(--userchallenge-status-completed));

  &.selected {
    .nano-button--light(var(--userchallenge-status-completed));
  }
}

//endregion

.send-button {
  .nano-button--heavy(var(--button-send));
}

.save-button {
  .nano-button--heavy(var(--button-save));
}

.delete-button {
  .nano-button--light(var(--button-delete));
}

.cancel-changes-button {
  .nano-button--heavy(var(--button-cancel));
}

.reading-challenge-correction-button {
  .nano-button--light(var(--button-reading-challenge-correction));
}

.admin-button {
  .nano-button--light(var(--admin-main));
}

.admin-button-2 {
  .nano-button--heavy(var(--admin-main));

  &:hover {
    img {
      filter: invert(12%) sepia(93%) saturate(7387%) hue-rotate(171deg) brightness(90%) contrast(103%);
    }
  }
}

.student-info-button {
  .nano-button--light(var(--primary-text-2));
  font-size: 1em;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}

.active--button {
  .nano-button--heavy(var(--button-active));
}

.action-button {
  .nano-button--heavy(var(--button-action));
}

.action-button-2 {
  .nano-button--heavy(var(--button-action-2));
}

.action-button-light {
  .nano-button--light(var(--button-action));
}

.main-button-light {
  .nano-button--light(var(--primary-text-1));
}

.info-button {
  .nano-button--light(var(--button-info));
}

.top-bar-button {
  .nano-button--heavy(var(--button-top-bar));
}

.back-button {
  .nano-button--heavy(var(--button-back));
}

.gallery-button {
  .nano-button--heavy(var(--button-gallery));
}

.challenge-activate-button {
  .nano-button--heavy(var(--challenge-activate));
}

.challenge-deactivate-button {
  .nano-button--heavy(var(--challenge-deactivate));
}

.signup-button {
  .nano-button--heavy(var(--button-signup));
}

.deeplink-button {
  .nano-button--light(var(--button-deeplink));
}

.notification-button {
  .nano-button--heavy(var(--student-pending));
}

.reading-template-button {
  .nano-button--heavy(var(--reading-challenge));
}

.writing-template-button {
  .nano-button--heavy(var(--writing-challenge));
}

.cancel-button-2 {
  .nano-button--heavy(var(--button-cancel));
  border-color: var(--button-cancel) !important;
}
