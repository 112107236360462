.nanopopup.share-babillard-popup,
.nanopopup.share-uc-popup {
  background-color: var(--babillard-color-10);
  overflow: hidden;

  .popup-section {
    flex-direction: column;
  }

  .close-container {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--babillard-color-9);
    left: 10px;
    top: 10px;
    z-index: 20;

    i,
    svg {
      font-size: 25px;
      color: var(--default-color-2);
    }

    &:hover {
      background-color: var(--default-color-2);

      i,
      svg {
        color: var(--babillard-color-9);
      }
    }

    @media screen and (max-width: 799px) {
      transform: scale(75%);
    }
  }

  .share-babillard-popup-content {
    position: relative;

    h2 {
      font-size: clamp(26px, 3vw, 40px);
      line-height: 1;
      color: var(--babillard-color-9);
      font-weight: 800;
      text-transform: none;
      margin: 0 0 1em;

      span {
        display: inline;
        font-size: clamp(26px, 3vw, 40px);
        line-height: inherit;
        color: var(--babillard-color-9);
        font-style: italic;
        * {
          display: inline;
          font-size: clamp(26px, 3vw, 40px);
          line-height: inherit;
          color: var(--babillard-color-9);
          font-style: italic;
        }
      }
    }

    h3 {
      font-size: clamp(18px, 3vw, 23px);
      line-height: 27px;
      color: var(--babillard-color-11);
      font-weight: 500;
      text-transform: none;
      margin: 0 0 1em;
    }

    p {
      color: var(--babillard-color-11);
      font-size: clamp(14px, 3vw, 18px);
      line-height: 22px;
      font-weight: 500;
      margin: 0 0 0.8em;
    }

    .challenge-share-link {
      padding: 10px;
      background-color: var(--default-color-2);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .action-buttons {
      margin: 2em 0 20px;
    }

    .share-babillard-main {
      padding: 0 20px;

      @media screen and (min-width: 800px) {
        padding: 0 40px 0 20%;
      }

      .note {
        p {
          color: var(--troubadour-main-5);
          font-size: clamp(16px, 2vw, 18px);
          font-style: normal;
          line-height: 1.2em;

          &:last-of-type {
            font-size: clamp(12px, 2vw, 14px);
          }
        }
      }
    }

    .share-babillard-disclaimer {
      margin: 0 6vw;

      hr {
        width: 100%;
        height: 1px;
        border: none;
        background-color: var(--babillard-color-12);
        margin: 0 0 1em;
      }

      p {
        font-size: 13px;
        line-height: 15px;
        font-style: italic;
      }
    }

    .footer-actions {
      margin: 0 4vw;
      text-align: right;
    }

    &.--not-shared {
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        bottom: unset;
        transform: translateY(-50%);
        width: 14vw;
        min-width: 80px;
        max-width: 191px;
        aspect-ratio: 191 / 410;
        background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/troub-squirrel-happy-side.png");
        background-repeat: no-repeat;
        background-size: cover;

        @media screen and (max-width: 799px) {
          display: none;
        }
      }

      .action-buttons {
        text-align: center;
      }
    }

    &.--shared {
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        bottom: unset;
        transform: translateY(-50%);
        width: 14vw;
        min-width: 80px;
        max-width: 160px;
        aspect-ratio: 182 / 399;
        background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/troub-squirrel-thumb-side.png");
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;

        @media screen and (max-width: 799px) {
          top: unset;
          bottom: -70px;
          transform: translateY(0);
        }
      }

      .action-buttons {
        text-align: left;
      }
    }
  }
}

// nanopopup overrides
@media (min-width: 1px),
  (max-width: 1280px),
  (max-width: 992px),
  (max-width: 767px),
  (max-width: 480px) {
  .nanopopup {
    &.share-babillard-popup,
    &.share-uc-popup {
      padding: 60px 0 30px !important;
      height: auto !important;
      min-height: auto !important;
      max-height: auto !important;
      min-width: 350px !important;
      max-width: 1010px !important;
    }
  }
}
