@import url("../../components/Buttons/TroubadourButtonTemplate.less");
@import url("../../components/Buttons/ConstellationButtons.less");

.download-passwords-button {
  .troubadour-button-template(var(--default-bg), var(--button-download-pwd));
}

.generic-lightgreen-btn {
  .troubadour-button-template(var(--primary-text-2), var(--onboarding-bg-6), var(--primary-text-2));
}

.generic-medgreen-btn {
  .troubadour-button-template(var(--default-color-2), var(--primary-text-2), var(--default-color-2));
}

.troubadour-access-button {
  .troubadour-button-template(var(--default-bg), var(--troubadour-main-2));
}

.constellation-access-button {
  .troubadour-button-template(var(--default-bg), var(--constellation-main-2));
}

.add-teacher-package {
  .troubadour-button-template(var(--default-color-2), var(--admin-main), var(--admin-main));
}

.add-family-package {
  .troubadour-button-template(var(--default-color-2), var(--quote-form-dark), var(--quote-form-dark));
}

.cart-checkout {
  .troubadour-button-template(var(--default-color-2), var(--troubadour-main-5));
}

.submit-button {
  .troubadour-button-template(var(--default-bg), var(--button-login));
}

.troubadour-login-button {
  .troubadour-button-template(var(--default-bg), var(--troubadour-main-2));
}

.constellation-login-button {
  .troubadour-button-template(var(--default-bg), var(--constellation-main-2));
}

.troubadour-infos-button {
  .troubadour-button-template(var(--troubadour-main-3), var(--default-bg));
}

.constellation-infos-button {
  .troubadour-button-template(var(--constellation-main), var(--default-bg));
}

.classic-classroom-button {
  .troubadour-button-template(var(--default-color-2), var(--primary-text-3));
}

.onboarding-continue-constellation {
  .troubadour-button-template(var(--default-color-2), var(--constellation-main-5));

  img {
    display: block;
    padding: 18px;
  }

  &:hover {
    .icon {
      transform: scale(1) !important;
    }
  }
}

.payment-modes {
  .troubadour-button-login-template(var(--default-color), var(--default-color-2));
}

.troub-learn-more {
  .troubadour-button-template(var(--package-learn-more-button-bg), var(--package-learn-more-button-fg));
}

.admin-logout-button {
  .troubadour-button-template(var(--default-color-2), var(--primary-text-1));
}

.product-troub-button {
  .troubadour-button-template(var(--default-color-2), var(--troubadour-main));
}

.product-cdo-button {
  .troubadour-button-template(var(--default-color-2), var(--constellation-main));
}

.manage-classroom-button {
  .troubadour-button-template(var(--default-color-2), var(--admin-main-2));
}

.add-classroom-button {
  .troubadour-button-template(var(--default-color-3), var(--button-add-group));
}

.purchase-subscription,
.admin-continue-btn,
.buy-more-packages-button {
  .troubadour-button-template(var(--default-color-2), var(--admin-main-4));
}

.micro-btn-white-no-icon {
  .troubadour-button-template(var(--default-color-8), var(--default-color-2));

  .button-text {
    font-size: 12px !important;
  }

  padding: 4px 10px !important;

  &::before {
    border-color: var(--default-color-8) !important;
  }
}

.remove-selected-students-button {
  .troubadour-button-template(var(--default-color-2), var(--classroom-management-color2));

  border: 1px solid var(--troubadour-main-4) !important;
}

.resync-classroom-button {
  .troubadour-button-template(var(--default-color-8), var(--default-color-2));

  box-shadow: var(--dropshadow);
}

.reject-student-button {
  .troubadour-button-template(var(--default-color-2), var(--troubadour-main));
}

.confirm-student-button {
  .troubadour-button-template(var(--default-color-2), var(--admin-2));
}

.cancel-manage-group-button {
  .troubadour-button-template(var(--troubadour-main-4), var(--default-color-2));

  border: 1px solid var(--troubadour-main-4) !important;
}

.quote-form-submit,
.request-quote,
.package-buy {
  .troubadour-button-template(var(--package-buy-button-fg), var(--package-buy-button-bg-1));
}

.activate-subscription {
  .troubadour-button-template(var(--default-color-2), var(--onboarding-bg));
}

.teacher-button {
  .troubadour-button-login-template(var(--teacher-info), var(--default-color-2));

  .button-text {
    color: var(--default-color) !important;
  }

  &:hover {
    .button-text {
      color: var(--default-color-2) !important;
    }
  }
}
