@import "../../../../resources/less/components/NanoPopup.less";

.nanopopup-premium {
  .nanopopup-template(var(--troubadour-main));
  padding: 20px 20px !important;
  background-color: var(--default-bg-2);
  min-width: 300px !important;
  max-width: 800px !important;
  overflow: visible;

  @media screen and (min-width: 800px) and (min-height: 600px) {
    padding: 40px 60px !important;
  }

  .close-container {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--default-bg);
    left: 15px;
    top: 15px;
    box-shadow: var(--dropshadow);

    i,
    svg {
      font-size: 20px;
      color: var(--troubadour-main-12);
    }

    &:hover {
      i,
      svg {
        color: var(--default-bg);
      }

      background-color: var(--troubadour-main-12);
    }
  }

  .visual {
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1 / 1;
    width: 10vw;
    min-width: 100px;
    margin: 0 auto 30px;
    background-color: var(--troubadour-main-16);
    border-radius: 50%;
    max-width: 200px;

    img {
      width: 40%;
    }
  }

  h2 {
    color: var(--troubadour-main);
    font-size: clamp(24px, 4vw, 40px);
    font-weight: 800;
    line-height: 1.1em;
    text-transform: none;
    text-align: center;
    margin: 0 0 30px;

    &.small-title {
      font-size: 23px;
      line-height: 1.2em;
    }
  }

  ul,
  ol {
    list-style-type: decimal;
    padding: 0 0 0 20px;
  }

  p,
  li {
    color: var(--default-color-4);
    font-size: clamp(14px, 3vw, 23px);
    line-height: 1.2em;
  }

  p {
    margin: 0 0 30px;
  }

  li {
    margin: 0 0 5px;
  }

  .scrollable-content {
    padding: 0 10px 0 0;
    height: 50vh;
    max-height: 250px !important;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--troubadour-main);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--default-bg-2);
      border-radius: 5px;
    }

    @supports not selector(::-webkit-scrollbar) {
      scrollbar-color: var(--troubadour-main) var(--default-bg-2);
      scrollbar-width: thin;
    }
  }

  .button-group {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    margin: 30px 0 0;
    padding: 0;
    gap: 20px;
  }

  &[data-popup-size="large"] {
    max-width: 800px !important;

    .button-group {
      justify-content: center;
    }
  }
}
