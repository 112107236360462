.nanopopup.information-page-popup {
  overflow: visible;
  background-color: var(--info-pages-popup-bg);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 25px;

  @media screen and (max-height: 768px) {
    height: 90vh;
    width: 90vw;
  }

  .close-container {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--info-pages-popup-close-bg);
    right: 15px;
    top: 15px;
    z-index: 10;

    svg {
      font-size: 20px;
      color: var(--info-pages-popup-close-fg);
    }

    &:hover {
      background-color: var(--info-pages-popup-close-hover-bg);
    }
  }

  .popup-section {
    display: flex;
    flex-direction: column;
    height: 100%;

    .information-page-popup-heading {
      padding: 0 !important;
      margin: 0 0 30px !important;
      text-align: center !important;
      font-size: clamp(24px, 3vw, 40px) !important;
      line-height: 1em !important;
      color: var(--info-pages-popup-heading) !important;
      text-transform: none !important;
    }

    .information-page-popup-content {
      color: var(--info-pages-popup-text) !important;
      font-size: clamp(16px, 3vw, 24px);
      line-height: 1.2em;
      width: 100%;
      padding-right: 20px;
      height: 40vh;
      margin: 0 0 40px;
      word-break: break-word;
      overflow-x: hidden;
      overflow-y: scroll;

      h1,
      h2,
      h3 {
        font-family: "Sophia Pro", sans-serif;
        line-height: 1.2em;
        font-weight: 800;
      }

      h1 {
        font-size: clamp(20px, 3vw, 34px);
      }

      h2 {
        font-size: clamp(18px, 3vw, 30px);
      }

      h3 {
        font-size: clamp(16px, 3vw, 28px);
      }

      p {
        font-weight: 400;
        font-size: clamp(14px, 3vw, 24px);
      }

      ol,
      ul {
        li {
          margin: 0 0 10px;
        }
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--info-pages-popup-scroll-thumb);
        border-radius: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--info-pages-popup-scroll-track);
        border-radius: 5px;
      }

      @supports not selector(::-webkit-scrollbar) {
        scrollbar-color: var(--info-pages-popup-scroll-thumb)
          var(--info-pages-popup-scroll-track);
        scrollbar-width: thin;
      }

      a {
        color: var(--news-troubadour-action-button-icon-bg);
      }
    }
  }

  .information-page-popup-confirm {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin: 0;
    gap: 20px 100px;
  }
}

// nanopopup overrides
@media (min-width: 1px),
  (max-width: 1280px),
  (max-width: 992px),
  (max-width: 767px),
  (max-width: 480px) {
  .nanopopup {
    &.information-page-popup {
      padding: clamp(20px, 4vw, 60px) !important;
      height: auto !important;
      min-height: auto !important;
      max-height: auto !important;
      min-width: 320px !important;
      max-width: 1000px !important;
    }
  }
}
