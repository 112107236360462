.top-progression-bar {
  margin-bottom: 25px;
  border-radius: 10px;

  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  background-color: var(--breadcrumb-text-2);
  padding: 20px 25px 32px 25px;

  &.small-bar {
    max-width: 992px;
  }

  .tuto-header {
    display: flex;
    align-items: center;
    padding-bottom: 15px;

    h1 {
      i {
        padding-right: 6px;
      }

      display: flex;
      margin: 0;
      font-size: 21px;
      font-weight: 700;
    }

    .group-icons {
      display: flex;
      margin-left: auto;
      align-items: center;
      flex-flow: row-reverse;

      .close-tutorial,
      .skip-step {
        padding: 5px;
        font-size: 16px;
        color: var(--breadcrumb-text-1);

        &:hover {
          cursor: pointer;
          opacity: 0.6;
        }
      }
    }
  }

  .breadcrumb-row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;

    .step-container {
      display: inline-flex;
      margin: 0 auto;
      flex-grow: 2;

      .step-block {
        margin: auto 10px;
        padding: 12px 8px;
        display: flex;
        border-radius: 12px;
        color: var(--breadcrumb-text-1);
        border: 1px solid var(--breadcrumb-text-1);
        width: 80%;
        align-content: center;
        font-weight: bold;
        opacity: 0.3;

        &:only-child {
          width: 100%;
        }

        &.done,
        &.current {
          opacity: 1;
        }

        &.done {
          border: 1px solid var(--breadcrumb-success);
          background-color: var(--breadcrumb-success);
          color: var(--breadcrumb-text-2);
        }

        &.current:hover {
          cursor: pointer;
          background-color: var(--breadcrumb-text-1);
          color: var(--breadcrumb-text-2);
        }

        div {
          margin: auto;
          text-align: center;

          &:only-child {
            margin: auto;
          }
        }

        svg {
          margin: auto 10px;
        }
      }

      .step-separator {
        width: 20%;
        border-radius: 10px;
        height: 7px;
        margin: auto;
        opacity: 0.3;
        background-color: var(--breadcrumb-text-1);

        &.done,
        &.current {
          opacity: 1;
        }

        &.done {
          background-color: var(--breadcrumb-success);
        }
      }
    }
  }
}

.logged-out {
  padding: 30px;
  margin: 35px;
}

@media (max-width: 767px) {
  .top-progression-bar {
    display: none;

    border-radius: 0;
    margin: 0 !important;
    padding: 10px;
    box-shadow: none !important;
    border-top: 1px solid var(--border);

    .breadcrumb-row {
      .step-inner {
        font-size: 12px;
      }
    }
  }

  .email-info-panel {
    border-radius: 0 !important;
    padding: 10px !important;
    box-shadow: none !important;
    border-bottom: 1px solid var(--border);

    .cartIcon i[data-fa-i2svg] {
      font-size: 24px !important;
    }

    .title {
      font-size: 22px !important;
    }

    .text {
      font-size: 14px !important;
    }
  }
}
