.down-page {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 20px);
  height: calc(100vh - 20px);
  border-style: solid;
  border-color: var(--default-color-2);
  border-width: 10px;
  gap: 10px;

  .product-section {
    flex-grow: 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;

    .top {
      width: 40%;
      height: auto;
      display: flex;

      img {
        display: inline-block;
        width: 30%;
        margin: auto;
        padding: 20px 0;
      }
    }

    .bottom {
      height: auto;
      width: 60%;
      display: flex;

      h1 {
        margin: auto;
        color: var(--default-color-2);
        font-size: clamp(16px, 3vw, 32px);
        text-align: center;
        padding: 0 20px;
      }
    }
  }

  .admin-section {
    .top {
      background-color: var(--admin-main-3);
    }

    .bottom {
      background-color: var(--admin-main-2);
    }
  }

  .constellation-section {
    .top {
      background-color: var(--constellation-main-2);
    }

    .bottom {
      background-color: var(--constellation-main-3);
    }
  }

  .troubadour-section {
    .top {
      background-color: var(--troubadour-main-2);
    }

    .bottom {
      background-color: var(--troubadour-main);
    }
  }

  @media screen and (min-height: 800px) and (min-width: 768px) {
    .product-section {
      .top {
        img {
          width: 50%;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    width: calc(100vw - 20px);
    flex-flow: row nowrap;

    .product-section {
      width: calc(33.333% - 7px);
      flex-direction: column;

      .top {
        width: 100%;
        height: 70%;

        img {
          padding: 0;
        }
      }

      .bottom {
        height: 30%;
        width: 100%;
      }
    }
  }
}
