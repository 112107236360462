.info-panel {
  margin-bottom: 25px;
  padding: 15px 60px 15px 15px;
  background: var(--landing-bg-3);
  box-sizing: border-box;
  border-radius: 12px;
  border: 1px solid var(--primary-text-2);
  display: flex;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  position: relative;

  [class*="col-"] {
    align-items: center;
  }

  .info-panel-image {
    padding: 0;
    align-items: center;
    width: 500px;
    height: 150px;
    position: relative;
    overflow: hidden;
    margin: 0;

    img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      transform: translate(-50%, -50%);
    }
  }

  .inner-text {
    padding: 10px;
  }

  .info-card-title {
    padding-bottom: 10px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 20px;
    color: var(-default-color-4);
    margin: auto;
  }

  .info-panel-text {
    font-style: normal;
    font-weight: normal;

    font-size: 16px;
    margin: auto;
    color: var(-default-color-4);
    line-height: 24px;

    a {
      color: var(--default-color-4);
      font-weight: bold;
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .close-info-panel {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -18px;
    top: -18px;
    background-color: var(--landing-bg-2);
    width: 36px;
    height: 36px;
    border-radius: 50%;

    i[data-fa-i2svg] {
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      color: var(--default-color-2);
      font-size: 20px;

      &:hover {
        color: var(--default-color);
        cursor: pointer;
      }

      &:active {
        opacity: 0.4;
      }
    }
  }

  .buttons-div {
    display: flex;
    margin: auto;

    .info-panel-button {
      margin: 10px auto auto auto;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    }
  }
}

.info-panel-aren {
  .info-panel();
  background-color: var(--aren);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

.student-overflow-container {
  width: 100%;

  .student-overflow-msg {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    background-color: var(--default-bg-3);
    margin: 40px;
    padding: 30px 40px;
    border-radius: 20px;
    box-shadow: var(--dropshadow);

    &::after {
      content: "";
      position: absolute;
      right: 25px;
      top: -30px;
      aspect-ratio: 78 / 114;
      width: 54px;
      background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/troub-paid-package-ribbon.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }

    h2 {
      color: var(--troubadour-main);
      font-size: 23px;
      font-weight: 700;
      line-height: 1em;
      margin: 0 0 20px;
      text-transform: none;
    }

    p {
      color: var(--default-color-4);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.2em;
      margin: 0 0 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .info-panel {
    padding: 10px;
    border-radius: 0;
    margin-bottom: 10px;
    box-shadow: none !important;

    .inner-text {
      padding: 5px;
    }

    .info-card-title {
      font-size: 14px;
    }

    .info-panel-text {
      font-size: 12px;
    }
  }
}
