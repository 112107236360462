@gradientMix: #fc60d2;
@subject: #9377f1;
@concept: #e59738;
@grade: #dd813f;
@language: #2081cb;
@user-region: #d3353e;

.dropdown {
  display: inline-block;

  button:not(.troubadour-button) {
    all: unset;

    &.show {
      .fa-angle-down,
      .fa-caret-down,
      .fa-chevron-down {
        transform: rotate(180deg);
      }
    }
  }

  button[data-open-under="true"] {
    position: relative;
    z-index: 910 !important;
  }

  &:hover {
    cursor: pointer;
  }

  .active-count {
    margin-left: 5px;
  }

  .fa-angle-down,
  .fa-caret-down,
  .fa-chevron-down {
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  &.active {
    .dropdown-toggle {
      background: var(--default-darker-bg) !important;
    }
  }

  .dropdown-backdrop {
    z-index: 880;
  }

  .dropdown-toggle {
    &::after {
      transition: all 0.2s linear;
    }

    &.show {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-button {
    display: flex;
    float: right;
    font-weight: 600;
    text-decoration: none;
    border-radius: 6px;
    padding: 8px 17px;
    background-color: var(--primary-text-1);
    border: 1px solid var(--primary-text-1);
    font-size: 15px;
    color: var(--default-color-2);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);

    &:hover {
      cursor: pointer;
      color: var(--primary-text-1);
      background-color: var(--default-color-2);
    }

    &:focus {
      box-shadow: none;
    }

    &.show {
      .fa-angle-down,
      .fa-caret-down,
      .fa-chevron-down {
        transform: rotate(180deg);
      }
    }

    .dropdown-text {
      display: inline-flex;
      align-items: center;
      font-weight: bold;
      text-decoration: none;
      margin: auto auto auto 0;
      white-space: nowrap;

      p {
        margin: 0 !important;
      }

      .status-icon {
        display: none;
      }

      :first-child:not(.half-left) {
        margin: auto 10px auto 0;
      }
    }

    svg {
      margin: auto 0 auto 15px;
    }
  }
}

.filter-menu {
  font-size: 15px;
}

.filters-reset {
  display: flex;
  float: right;
  margin: auto 0 7px 20px !important;
  font-weight: 600;
  text-decoration: none;
  border-radius: 6px;
  padding: 8px;
  background-color: var(--primary-text-1);
  border: 1px solid var(--primary-text-1);
  font-size: 18px;
  color: var(--default-color-2);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);

  &:hover {
    background: var(--default-color-2);
    color: var(--primary-text-1);
    cursor: pointer;
    border: 1px solid var(--primary-text-1);
  }

  &:focus {
    box-shadow: none;
  }

  svg {
    margin: auto 0;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}

.square {
  height: 15px;
  width: 15px;
  margin-right: 10px;
  border-radius: 50%;
  align-self: center;
  background-color: var(--disabled);
  border: 2px solid var(--default-color-2);
}

.filter--color(@color) {
  a {
    margin: 0;
    color: @color !important;

    &:hover {
      background-color: @color !important;
      color: var(--default-color-2) !important;
    }
  }

  .square {
    background-color: @color;
  }

  .status-icon {
    font-size: 16px;
    margin: auto 5px auto 0;
    color: @color, 0.8;
    opacity: 1;
  }

  .filter-active {
    background-color: @color !important;
    color: var(--default-color-2) !important;
    opacity: 1;
    font-weight: bold;

    &:hover {
      background-color: @color;
      color: var(--default-color-2);
      opacity: 0.6;
    }

    .status-icon {
      color: @color, 0.8;
      opacity: 0.8;
    }
  }
}

.filter--color--multiple(@textColor, @leftColor, @rightColor) {
  .filter--color(@textColor);

  .half-left,
  .half-right {
    height: 15px;
    width: 7.5px;
    padding: 0;
    margin-right: 10px;
    align-self: center;
  }

  .half-left {
    background-color: @leftColor;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    margin-right: 2px;
  }

  .half-right {
    background-color: @rightColor;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    margin-right: 10px;
  }
}

.filter--gradiant(@color) {
  &:nth-of-type(4n + 0) {
    .filter--color(mix(@color, @gradientMix, 100%));
  }

  &:nth-of-type(4n + 1) {
    .filter--color(mix(@color, @gradientMix, 75%));
  }

  &:nth-of-type(4n + 2) {
    .filter--color(mix(@color, @gradientMix, 50%));
  }

  &:nth-of-type(4n + 3) {
    .filter--color(mix(@color, @gradientMix, 25%));
  }
}

.filter--all {
  .filter--color(var(--dropdown-all));
}

.filter--to-review {
  .filter--color(var(--userchallenge-status-to-review));
}

.filter--in-progress {
  .filter--color(var(--userchallenge-status-construction));
}

.filter--need-revision {
  .filter--color(var(--userchallenge-status-revision));
}

.filter--completed {
  .filter--color(var(--userchallenge-status-done));
}

.filter--group-0 {
  .filter--color(var(--filter-group-1));
}

.filter--group-1 {
  .filter--color(var(--filter-group-2));
}

.filter--archived {
  .filter--color(var(--student-archived));
}

.filter--pending {
  .filter--color(var(--student-pending));
}

.filter--enabled {
  .filter--color(var(--challenge-activate));
}

.filter--disabled {
  .filter--color(var(--challenge-deactivate));
}

.filter--deleted {
  .filter--color(var(--button-removed));
}

.filter--writing {
  .filter--color(var(--writing-challenge));
}

.filter--reading {
  .filter--color(var(--reading-challenge));
}

.filter--template-all {
  .filter--color--multiple(var(--dropdown-all), var(--writing-challenge), var(--reading-challenge));
}

.filter--grade {
  .filter--gradiant(@grade);
}

.filter--concept {
  .filter--gradiant(@concept);
}

.filter--subject {
  .filter--gradiant(@subject);
}

.filter--language,
.filter--studied-language {
  .filter--gradiant(@language);
}

.filter--region {
  .filter--gradiant(@user-region);
}

.option--creation {
  .filter--color(var(--button-action));
}

.type-kebab {
  .kebab {
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    font-size: 16px;
    vertical-align: center;
    padding: 5px 8px;
    border-radius: 3px;
    border: none;
    background-color: transparent;
  }

  .kebab:hover {
    cursor: pointer;
    opacity: 1 !important;
    background-color: rgba(0, 0, 0, 0.08) !important;
  }

  .dropdown-menu {
    min-width: 225px;

    @media screen and (max-width: 799px) {
      min-width: 100px;
    }
  }

  li a {
    color: var(--default-color);
    background-color: var(--default-bg);

    &:hover {
      color: var(--default-bg);
      background-color: var(--default-color) !important;
    }
  }
}

.type-text {
  li a {
    color: var(--default-color);
    background-color: var(--default-bg);

    &:hover {
      color: var(--default-bg);
      background-color: var(--default-color) !important;
    }
  }
}

.type-add {
  .add {
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    font-size: 16px;
    vertical-align: center;
    padding: 5px;
    border-radius: 3px;
    border: none;
    background-color: transparent;

    span {
      color: var(--default-color);
      opacity: 0.6;
    }
  }

  .add:hover {
    cursor: pointer;
    opacity: 1 !important;
    background-color: rgba(0, 0, 0, 0.08) !important;
  }

  .dropdown-menu {
    min-width: 225px;

    @media screen and (max-width: 799px) {
      min-width: 100px;
    }
  }

  li a {
    color: var(--default-color);
    background-color: var(--default-bg);

    &:hover {
      color: var(--default-bg);
      background-color: var(--default-color) !important;
    }
  }
}

.dropdown-menu--top {
  position: absolute;
  right: 0 !important;
  bottom: 100%;
  top: initial !important;
  margin-bottom: 45px !important;
  animation-name: slidenavAnimationTop;
}

.dropdown-menu--bottom {
  position: absolute;
  right: 0 !important;
  top: 100%;
  margin-top: 45px !important;
  animation-name: slidenavAnimationDown;
}

.dropdown-menu--bottom-left {
  position: absolute;
  left: 0 !important;
  right: unset !important;
  top: 100%;
  margin-top: 45px !important;
  animation-name: slidenavAnimationDown;
}

.dropdown-menu--bottom-right {
  position: absolute;
  left: unset;
  right: 0 !important;
  top: 100%;
  margin-top: 45px !important;
  animation-name: slidenavAnimationDown;
}

.dropdown-menu--left {
  top: 0;
  right: 100%;
  margin-right: 6px !important;
  left: initial !important;
  animation-name: slidenavAnimationLeft;
}

.dropdown-menu--right {
  top: 0;
  left: 100%;
  margin-left: 45px !important;
  animation-name: slidenavAnimationRight;
}

// Text Entry Sticker Selection Menu
.dropdown-menu--sticker-menu {
  top: 0;
  right: 100%;
  margin-right: 45px !important;
  left: initial !important;
  animation-duration: 0.2s !important;
  animation-name: slidenavAnimationStickerMenu;
}

.dropdown-menu {
  left: auto !important;
  z-index: 900;
  border-radius: 6px;
  padding: 0;
  animation-duration: 0.4s;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  animation-fill-mode: forwards;
  flex-direction: column;

  span {
    font-weight: bold;
  }

  li a {
    display: flex;
    color: var(--default-color);
    background-color: var(--default-bg);
    padding: 10px 20px;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    span {
      padding: 0 5px;
      font-weight: 700;
    }

    &.dropdown-item-hidden {
      opacity: 0.3;
      pointer-events: none;
      cursor: initial;
    }

    &:hover {
      color: var(--default-bg);
      background-color: var(--default-color);
    }

    @media screen and (max-width: 799px) {
      padding: 5px 10px;
    }
  }

  li:first-child {
    a {
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }
  }

  li:last-child {
    a {
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }

  .dropdown-item {
    font-size: 15px;

    img,
    svg {
      margin: auto 10px;
      font-size: 19px;
    }
  }
}

.dropdown-submenu {
  position: relative;

  &:hover {
    .dropdown-menu {
      display: block;
    }
  }
}

.last {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.dropdown-menu--noline {
  border: none !important;
}

.dropdown-menu li:hover a {
  cursor: pointer;
  background: var(--default-bg);
}

.dropdown-menu-solo li {
  border: 0 solid var(--border) !important;
}

.data-panel {
  min-width: 350px !important;
  margin-right: 1px !important;

  li {
    padding: 8px 8px;
    color: var(--default-color);
  }

  .data-title {
    background-color: var(--default-bg);
    border-top: 1px solid var(--border);
    border-bottom: 1px solid var(--border);
    display: flow-root;
  }

  .data-value {
    display: flow-root;
  }

  .key {
    float: left;
    font-weight: normal;
  }

  .value {
    float: right;
  }
}

//-- Down
@keyframes slidenavAnimationDown {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

//-- Left
@keyframes slidenavAnimationLeft {
  from {
    transform: translateX(10px);
    opacity: 0;
  }

  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

//-- Top
@keyframes slidenavAnimationTop {
  from {
    transform: translateY(10px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

// -- Right
@keyframes slidenavAnimationRight {
  from {
    transform: translateX(-10px);
    opacity: 0;
  }

  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

//-- Text Entry Sticker Selection Menu
@keyframes slidenavAnimationStickerMenu {
  from {
    transform: translateX(-60px);
    opacity: 0;
  }

  to {
    transform: translateX(-100px);
    opacity: 1;
  }
}
