.skippy-toast-container {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 80%;
  max-width: 1100px;
  height: auto;
  transition: all 0.4s ease-in-out;
  z-index: 1000;
  outline: none;
  opacity: 1;

  &.skippy-toast-closed {
    bottom: -3000px;
    opacity: 0;
  }

  &.close-fast {
    transition: all 0.2s ease-in-out;
  }

  .skippy-toast-image {
    position: absolute;
    bottom: 0;
    left: 0;
    aspect-ratio: 250 / 300;
    width: 25%;
    z-index: 20;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-end;

    img {
      width: auto;
      max-width: 100%;
    }

    &.troub-squirrel-questioning {
      aspect-ratio: 250 / 373;
    }

    &.troub-squirrel-smile {
      aspect-ratio: 1008 / 1437;
    }

    &.troub-squirrel-think {
      aspect-ratio: 1074 / 1534;
    }

    &.troub-squirrel-happy {
      aspect-ratio: 1198 / 1662;
    }

    &.troub-squirrel-waiting {
      aspect-ratio: 297 / 351;
    }

    &.troub-squirrel-pleased {
      aspect-ratio: 233 / 311;
    }

    &.troub-squirrel-information {
      aspect-ratio: 297 / 351;
    }

    &.troub-squirel-correction-1 {
      aspect-ratio: 350 / 380;
    }

    &.troub-squirel-correction-2 {
      aspect-ratio: 350 / 380;
    }

    &.troub-squirel-correction-3 {
      aspect-ratio: 350 / 380;
    }

    &.troub-squirel-correction-4 {
      aspect-ratio: 400 / 380;
    }

    &.troub-toast-holiday-slide {
      aspect-ratio: 215 / 381;
    }

    &.troub-toast-model-importance {
      aspect-ratio: 330 / 348;
    }

    &.troub-toast-writing-motivation {
      aspect-ratio: 211 / 372;
    }

    &.troub-toast-adult-involvement {
      aspect-ratio: 368 / 297;
    }

    &.troub-toast-have-audience {
      aspect-ratio: 385 / 306;
    }

    &.troub-toast-writing-naturally {
      aspect-ratio: 287 / 363;
    }

    &.pixelated-family-troubadour {
      aspect-ratio: 304 / 289;
    }
  }

  .skippy-toast-icon {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    width: 80px;
    height: 80px;
    z-index: 20;
    overflow: hidden;

    img {
      width: 80px;
      aspect-ratio: 1;
    }
  }

  .skippy-toast-inner {
    position: relative;
    z-index: 10;
    padding: 20px 50px 20px 0;
    border-radius: 30px 30px 0 0;
    font-size: clamp(14px, 2vw, 23px);
    line-height: 1.1em;
    font-weight: 800;

    &.has-image {
      padding-left: 25%;
    }
    &.has-icon {
      padding-left: 120px;
    }

    .skippy-toast-close-btn {
      position: absolute;
      right: 10px;
      top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--default-bg);
      cursor: pointer;

      i,
      svg {
        width: 25px;
        height: 25px;
        color: var(--default-color);
      }

      &:hover {
        background-color: var(--button-active);
      }
    }

    h3 {
      font-size: clamp(18px, 3vw, 40px);
      line-height: 1em;
      font-weight: 800;
      margin-bottom: 15px;
    }

    p {
      font-size: clamp(14px, 2vw, 23px);
      line-height: 1.2em;
      font-weight: 400;

      a {
        color: inherit;
        text-decoration: underline;
      }

      &.footer {
        font-size: clamp(12px, 2vw, 18px);
      }
    }

    &.color-scheme-IN_PROGRESS {
      color: var(--default-color-2);
      background-color: var(--workstate-inprog-dark);

      .skippy-toast-close-btn {
        svg {
          color: var(--workstate-inprog-light);
        }
      }

      h3 {
        color: var(--workstate-inprog);
      }

      p {
        span {
          color: var(--workstate-needrev-light);
        }
      }
    }

    &.color-scheme-NEED_REVISION {
      color: var(--default-color-2);
      background-color: var(--workstate-needrev-dark);

      .skippy-toast-close-btn {
        svg {
          color: var(--workstate-needrev-dark);
        }
      }

      h3 {
        color: var(--workstate-needrev);
      }
    }

    &.color-scheme-WAITING_FOR_VALIDATION {
      color: var(--default-color-2);
      background-color: var(--workstate-waitval-very-dark);

      .skippy-toast-close-btn {
        svg {
          color: var(--workstate-waitval-dark);
        }
      }

      h3 {
        color: var(--workstate-waitval-light);
      }
    }

    &.color-scheme-COMPLETED {
      color: var(--default-color-2);
      background-color: var(--workstate-completed-very-dark);

      .skippy-toast-close-btn {
        svg {
          color: var(--workstate-completed-very-dark);
        }
      }

      h3 {
        color: var(--workstate-completed-light);
      }
    }

    &.color-scheme-ASSIGNED {
      color: var(--student-challenges-color-10);
      background-color: var(--student-challenges-color-2);

      .skippy-toast-close-btn {
        svg {
          color: var(--student-challenges-color-10);
        }
      }

      h3 {
        color: var(--student-challenges-color-1);
      }
    }

    &.color-scheme-UNASSIGNED {
      color: var(--student-challenges-color-12);
      background-color: var(--student-challenges-color-8);

      .skippy-toast-close-btn {
        svg {
          color: var(--student-challenges-color-12);
        }
      }

      h3 {
        color: var(--student-challenges-color-16);
      }
    }

    &.color-scheme-ARCHIVED {
      color: var(--student-challenges-color-4);
      background-color: var(--student-challenges-color-9);

      .skippy-toast-close-btn {
        svg {
          color: var(--student-challenges-color-14);
        }
      }

      h3 {
        color: var(--student-challenges-color-5);
      }
    }

    &.color-scheme-UNFINISHED {
      color: var(--text-entry-completed-bg3);
      background-color: var(--challenge-activate);

      .skippy-toast-close-btn {
        svg {
          color: var(--workstate-waitval-dark);
        }
      }

      h3 {
        color: var(--text-entry-completed-bg3);
      }
    }

    &.color-scheme-CORRECTION {
      color: var(--student-challenges-color-24);
      background-color: var(--student-challenges-color-10);

      .skippy-toast-close-btn {
        svg {
          color: var(--workstate-waitval-dark);
        }
      }

      h3 {
        color: var(--student-challenges-color-24);
      }
    }

    &.color-scheme-TROUBGRADIENT {
      color: var(--default-color-2);
      background: transparent var(--troubadour-section-bg3);
      padding-top: 40px;
      padding-bottom: 40px;

      .skippy-toast-close-btn {
        svg {
          color: var(--news-nanomonx-close-button);
        }
      }

      h3 {
        color: var(--default-color-2);
      }
    }

    .skippy-toast-button {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }

    &.color-scheme-ADMIN_LIGHT {
      color: var(--admin-main-2);
      background-color: var(--onboarding-bg-6);
      border-color: var(--admin-main-2);
      border-width: 2px 2px 0 2px;
      border-style: solid;

      .skippy-toast-close-btn {
        svg {
          color: var(--admin-main-2);
        }
      }

      h3 {
        color: var(--admin-main);
      }
    }

    &.color-scheme-MOREINFO {
      color: var(--default-color-2);
      background-color: var(--tutorial-color-3);

      .skippy-toast-close-btn {
        svg {
          color: var(--tutorial-color-3);
        }
      }

      h3 {
        color: var(--onboarding-bg-9);
      }
    }

    .skippy-toast-button {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }
  }

  @media screen and (max-width: 799px) {
    .skippy-toast-icon,
    .skippy-toast-image {
      top: unset;
      right: unset;
      left: 50%;
      transform: translateX(-50%);
    }

    .skippy-toast-icon {
      bottom: 10px;
    }

    .skippy-toast-image {
      max-height: 180px;
      bottom: 0;
    }

    .skippy-toast-image {
      width: 100%;

      img {
        max-height: 180px;
      }
    }

    .skippy-toast-inner {
      .skippy-toast-close-btn {
        width: 30px;
        height: 30px;

        i,
        svg {
          width: 20px;
          height: 20px;
        }

        &:hover {
          background-color: var(--button-active);
        }
      }

      &.has-image {
        padding: 40px 30px 180px 30px;
      }
      &.has-icon {
        padding: 40px 30px 100px 30px;
      }
    }
  }
}
