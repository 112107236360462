.licence-subnav {
  border-radius: 10px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  background-color: var(--default-bg);
  padding: 12px;
  margin-bottom: 25px;

  ul {
    text-align: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      flex: 1 1 auto;
      display: flex;

      i {
        font-size: 15px;
        margin-right: 3px;
      }

      a {
        width: 100%;
      }

      a:not(.dropdown-item) {
        font-size: 15px;
        font-weight: 400;
        opacity: 0.8;
        border-radius: 6px;
        padding: 10px;
        color: var(--left-menu-color);
        margin: 2px;
        transition: all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
      }

      @media (max-width: 767px) {
        a:not(.dropdown-item) {
          padding: 12px 6px;
          color: var(--left-menu-color);
          margin: 6px;
          transition: all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
        }
      }

      &:hover:not(.active):not(.dropdown):not(.sub-active) {
        a {
          cursor: pointer;
        }
      }

      &.disabled {
        a {
          cursor: not-allowed !important;
          pointer-events: none !important;
          opacity: 0.3 !important;
        }
      }

      &.active {
        a {
          color: var(--primary-text-1);
          background: var(--default-darker-bg);
        }
      }

      .licence-subnav-dropdown {
        width: 100%;
        background-color: var(--default-bg);
        border: 2px solid var(--default-bg);
        box-shadow: var(--dropshadow3);
        border-radius: 6px;

        a {
          display: flex;
          color: var(--left-menu-color);
          padding: 10px 20px;
          text-decoration: none;
          transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
          background: var(--default-bg);
          border-radius: 6px;
          white-space: wrap;

          &:hover {
            cursor: pointer;
            background: var(--default-darker-bg);
          }
        }
      }
    }
  }
}
