@import "Webkits.less";
@import "../../../../node_modules/@fortawesome/fontawesome-free/less/fontawesome.less";
@import "components/Typography";
@import "components/FormWalls";
@import "components/Tooltip.less";
@import "components/Navigation.less";
@import "components/ErrorPages.less";
@import "components/LeftMenu.less";
@import "components/FormValidation";
@import "components/Responsive";
@import "components/InformationPanel";
@import "components/breadcrumbRow";
@import "components/DropDownMenu";
@import "components/LoadingPlaceholder";
@import "components/NanoToast";
@import "components/SkippyToast";
@import "components/NanoPopup";
@import "components/actionPopup";
@import "components/AdminLinks";

html,
body {
  position: relative;
  font-weight: 400;
  color: var(--default-color);

  &.no-scroll {
    overflow: hidden;
    // the second touch-action will replace the first one if available. Useful for old iPads
    touch-action: none;
    touch-action: pinch-zoom;
  }
}

html {
  box-sizing: border-box;
}

body {
  font-family: "Sofia Pro", sans-serif;
  font-size: 14px;
  background-color: var(--default-bg);
  margin: 0;
}

a {
  cursor: pointer;
  text-decoration: none;
}

footer {
  width: 100%;
  margin-top: auto;
  padding: 0;
  text-align: center;
}

label {
  margin: 10px 0 0 0;
}

@media print {
  html,
  body {
    font-family: "Times New Roman", sans-serif;
  }

  header,
  footer,
  h1 {
    display: none;
  }

  thead th {
    border: none;
  }
}

.tooltip {
  .tooltip-inner {
    max-width: none;
    border-radius: 6px !important;
    font-size: 13px;
    padding: 4px 10px !important;
  }
}

.container {
  padding: 0;
  display: block;
  position: relative;
}

//Main container styling
#main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;

  // Support site
  &.main-support {
    padding: 80px 40px 40px 120px;
  }
}

// Main container with full height header (Logged-out / default)
.navigation-container ~ #main-container {
  padding-top: 80px;

  @media screen and (min-width: 1081px) {
    padding-top: 100px;
  }
}

// Main container with compact header (Logged-in / class override)
.navigation-container.connected ~ #main-container:not(.main-support),
.navigation-container ~ #main-container.compact-topbar:not(.main-support) {
  padding-top: 40px;

  @media screen and (min-width: 1081px) {
    padding-top: 40px;
  }
}

// Main container with sidenav (excluding Support site)
.navigation-container.side-menu-active ~ #main-container:not(.main-support) {
  @media screen and (min-width: 769px) {
    padding-left: 150px;
  }
}

.empty-field {
  padding: 80px;
  background: var(--default-bg);
  text-align: center;
  overflow-x: unset !important;
  border-radius: 12px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);

  .empty-field-icon {
    font-size: 36px;
    padding: 0;
    margin: 0;
    opacity: 0.3;
  }

  h1 {
    padding: 5px 10px 25px 10px;
    font-size: 17px;
    font-weight: 700;
    margin: 0;
    opacity: 0.8;
    color: var(--default-color);
  }
}

.pixel-img {
  image-rendering: pixelated;
}

.buttons-wrapper {
  display: inline-flex;
  flex: 0;

  .dropdown {
    margin: auto;
  }
}

.hide,
.hidden {
  display: none !important;
}

@media (max-width: 1200px) {
  .licence-subnav ul {
    display: block !important;
    text-align: left !important;
  }

  .left-menu {
    height: 100%;
  }
}

.block-disabled {
  background-color: var(--default-color-5);
  border-radius: 10px;

  * {
    pointer-events: none;
    opacity: 0.6;
  }
}

// force new line ion flex box
.break {
  flex-basis: 100%;
  height: 0;
}
