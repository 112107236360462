.nanopopup.paid-feature-info-popup {
  background-color: var(--default-bg-2);
  overflow: visible;

  &::after {
    content: "";
    position: absolute;
    right: 40px;
    top: -30px;
    aspect-ratio: 78 / 114;
    width: 78px;
    background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/troub-paid-package-ribbon.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .paid-feature-message {
    display: none;

    h2 {
      color: var(--troubadour-main);
      font-size: clamp(24px, 3vw, 40px);
      font-weight: 800;
      line-height: 1em;
      text-transform: none;
      margin: 0 0 30px;
      padding: 0 100px 0 0;
      word-break: break-word;
    }

    p {
      color: var(--default-color-4);
      font-size: clamp(18px, 2vw, 23px);
      line-height: 1em;
      text-transform: none;
      margin: 0 0 30px;
    }
  }

  &[data-feature-name="default-paid-feature"] {
    .paid-feature-message {
      &.default-paid-feature {
        display: block;
      }
    }
  }
  &[data-feature-name="challenge-from-scratch"] {
    .paid-feature-message {
      &.challenge-from-scratch {
        display: block;
      }
    }
  }
  &[data-feature-name="challenge-from-catalog"] {
    .paid-feature-message {
      &.challenge-from-catalog {
        display: block;
      }
    }
  }
  &[data-feature-name="challenge-masking"] {
    .paid-feature-message {
      &.challenge-masking {
        display: block;
      }
    }
  }
  &[data-feature-name="challenge-correction"] {
    .paid-feature-message {
      &.challenge-correction {
        display: block;
      }
    }
  }

  .action-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;

    @media screen and (min-width: 500px) {
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}

// nanopopup overrides
@media (min-width: 1px),
  (max-width: 1280px),
  (max-width: 992px),
  (max-width: 767px),
  (max-width: 480px) {
  .nanopopup {
    &.paid-feature-info-popup {
      padding: 30px !important;
      width: 90vw !important;
      height: auto !important;
      min-width: 300px !important;
      max-width: 560px !important;
      min-height: auto !important;
      max-height: 450px !important;
    }
  }
}
