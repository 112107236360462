.form-group {
  margin-bottom: 30px;
}

.forgot-password-container {
  align-items: center;
  max-width: 992px;
  margin: 0 auto;
  padding: 0 40px;

  .inner {
    padding: 25px;
    height: auto;
    text-align: center;
    color: var(--primary-text-1);

    &.expired-link {
      color: var(--content-invalid);
    }

    i,
    svg {
      font-size: 32px;
    }

    .title {
      font-weight: 700;
      font-size: 24px !important;
    }

    .text {
      font-size: 16px !important;
    }

    .email-forgot-form {
      padding: 15px 50px 30px 50px;

      label {
        display: none;
      }

      input {
        border: 1px solid var(--primary-text-1);
        text-align: center;
        margin: 25px 0;
      }

      ul {
        list-style-type: none;
        color: var(--content-invalid);
        padding: 10px 0 0 0 !important;
        white-space: nowrap;
        font-weight: 400;
        font-size: 14px;
      }

      button {
        border-radius: 6px;
        font-weight: 600;
        font-size: 16px;
        padding: 11px 25px;
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        background-color: var(--primary-text-1);
        color: var(--default-color-2);
        border: 1px solid var(--border);

        &:hover {
          background-color: var(--primary-text-2-dark);
        }
      }
    }
  }
}

.account-wall {
  background-color: var(--default-bg);
  border-radius: 10px;
  max-width: 920px;
  margin: 0 auto;

  .username-button-container {
    display: flex;

    a {
      margin: auto 0 auto auto;
    }
  }

  .action-button-group {
    display: flex;
    padding: 50px 0 25px 0;

    button,
    a {
      min-width: 20%;
      margin: auto 15px auto 0;
    }

    .float-end {
      margin: auto 0 auto auto;
    }
  }

  form {
    padding: 15px;
    margin: 0 auto;

    .label-hint {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      opacity: 0.8;
      margin: 0;
      color: var(--default-color);
    }

    .password-edit-group {
      margin-top: 35px !important;
    }

    .password-button {
      text-align: right;

      a {
        text-decoration: none;
        color: var(--primary-text-1);
        font-size: 16px;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .nano-button-micro {
      margin: 5px 0;
    }
  }

  h1 {
    font-size: 24px;
    display: block;
    color: var(--default-color);
    opacity: 0.6;
    padding: 68px 0 40px;
    max-height: 80px;
    margin-left: 28px;
  }

  h2 {
    font-size: 17px;
    font-weight: bold;
    text-align: left;
    margin-top: 0;
    color: var(--default-color);
  }

  .form-control {
    border: 2px solid var(--primary-text-1);
  }
}

.account-action-wall {
  background-color: var(--default-bg);
  border-radius: 10px;
  max-width: 720px;
  margin: 0 auto;
  padding: 15px;

  form {
    margin: 0 auto;
  }

  h1 {
    font-size: 23px;
    font-weight: bold;
    display: block;
    padding: 30px 0 30px;
    margin: 0 auto;
    text-align: center;
  }

  h2 {
    font-size: 16px;
    text-align: center;
    padding: 20px 0;
    color: var(--default-color);
  }
}

@media (max-width: 992px) {
  .account-wall {
    form .password-button {
      text-align: left;
    }

    .action-button-group {
      padding: 25px 0;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .cancel-button,
      button {
        font-size: 14px !important;
        padding: 7px 10px !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .forgot-password-container {
    .inner {
      .text {
        white-space: initial;
      }

      .email-forgot-form {
        padding: 0;

        .nano-button-submit {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
}
