.troubadour-button-template(@color1: var(--default-bg), @color2: var(--default-color), @border: var(--button-border), @hovertxt: var(--default-bg)) {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  align-self: center;

  &:focus {
    text-decoration: none;
  }

  &[data-icon-position="left"] {
    flex-direction: row-reverse;
  }

  .button-text {
    font-family:
      Sofia Pro,
      sans-serif;

    &.ws-no-wrap {
      white-space: nowrap;
    }
  }

  &:active,
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }

  // Legacy button type
  &.legacy {
    position: relative;
    font-size: 20px;
    opacity: 1;
    border-radius: 45px;
    transition: 250ms all;
    background: none;
    border: none;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: @color1;
      border: 2px solid @color2;
      border-radius: inherit;
      z-index: 10;
    }

    &.has-shadow {
      &::before {
        border: 2px solid @color1;
        box-shadow: var(--dropshadow);
      }
    }

    .button-text,
    .icon {
      z-index: 11;
    }

    .button-text {
      color: @color2;
      font-weight: normal;
      z-index: 11;
    }

    svg {
      color: @color2;
    }

    &:hover,
    &.selected {
      &::before {
        background-color: @color2;
        border: 2px solid @color2;
      }

      .button-text {
        color: @color1;
      }

      img {
        transform: scale(0.95, 0.95);
      }
    }

    &.disabled {
      pointer-events: none;

      &::before {
        border-color: var(--ui-disabled);
        background-color: var(--ui-disabled-2);
      }

      .button-text {
        color: var(--ui-disabled);
      }

      .icon {
        opacity: 0.5;
      }
    }

    &.invalid {
      &::before {
        border-color: var(--ui-disabled);
        background-color: var(--ui-disabled-2);
      }

      .button-text {
        color: var(--ui-disabled);
      }
    }

    &.waiting {
      animation: waitingButtonAnimation 1750ms alternate infinite ease-in-out;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 30px;

      img {
        width: 100%;
        height: 100%;
        transition: 250ms all;
        margin: 0 !important;
      }
    }

    &[data-size="tiny"] {
      padding: 8px 25px;
      border-radius: 45px;

      .icon {
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
      }

      .button-text {
        font-size: 16px;
        line-height: 21px;
        font-weight: 600;
      }

      &[data-icon-position="left"] {
        padding-left: 20px;

        .icon {
          margin-right: 8px;
        }
      }

      &[data-icon-position="right"] {
        padding-right: 20px;

        .icon {
          margin-left: 8px;
        }
      }
    }

    &[data-size="small"] {
      padding: 11px 30px;
      border-radius: 45px;

      .icon {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
      }

      .button-text {
        font-size: 20px;
        line-height: 25px;
        font-weight: 800;
      }

      &[data-icon-position="left"] {
        padding-left: 25px;

        .icon {
          margin-right: 10px;
        }
      }

      &[data-icon-position="right"] {
        padding-right: 25px;

        .icon {
          margin-left: 10px;
        }
      }
    }

    &[data-size="regular"] {
      padding: 20px 40px;
      border-radius: 45px;

      .icon {
        width: 28px;
        height: 28px;
        min-width: 28px;
        min-height: 28px;
      }

      .button-text {
        font-size: 26px;
        line-height: 35px;
        font-weight: 600;
      }

      &[data-icon-position="left"] {
        padding-left: 35px;

        .icon {
          margin-right: 18px;
        }
      }

      &[data-icon-position="right"] {
        padding-right: 35px;

        .icon {
          margin-left: 18px;
        }
      }
    }
  }

  // All purpose pill style button
  &.pill {
    position: relative;
    font-size: 20px;
    opacity: 1;
    border-radius: 45px;
    transition: 250ms all;
    background: none;
    border: none;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: @color2;
      border: 2px solid @color2;
      border-radius: inherit;
      box-shadow: var(--dropshadow);
      z-index: 10;
    }

    .button-text,
    .icon {
      z-index: 11;
    }

    .button-text {
      color: @color1;
      font-weight: normal;
      z-index: 11;
    }

    svg {
      color: @color1;
    }

    &:hover,
    &.selected {
      &::before {
        filter: brightness(1.3);
      }

      img {
        transform: scale(0.95, 0.95);
      }
    }

    &.no-shadow {
      &::before {
        box-shadow: none;
      }
    }

    &.has-border {
      &::before {
        border-color: @border;
        box-shadow: none !important;
      }

      &:hover,
      &.selected {
        &::before {
          border-color: @border !important;
          box-shadow: none !important;
        }
      }
    }

    &.alt-hover {
      &:hover,
      &.selected {
        &::before {
          border-color: @color1;
          background-color: @color1;
          filter: brightness(1);
        }

        .icon,
        .button-text {
          color: @color2;
        }
      }
    }

    &.disabled,
    &.invalid {
      color: var(--disabled-text);
      font-weight: lighter;
      pointer-events: none;
      filter: grayscale(1);

      &::before {
        background-color: var(--disabled);
        border-color: var(--disabled-border);
      }
    }

    &.waiting {
      animation: waitingButtonAnimation 1750ms alternate infinite ease-in-out;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 30px;

      img {
        width: 100%;
        height: 100%;
        transition: 250ms all;
        margin: 0 !important;
      }
    }

    &[data-icon-position="null"] {
      .icon {
        display: none !important;
      }
    }

    &[data-size="micro"] {
      padding: 4px 10px;
      border-radius: 25px;

      .icon {
        width: 18px;
        height: 18px;
        min-width: 18px;
        min-height: 18px;
      }

      .button-text {
        font-size: 12px;
        line-height: 18px;
        font-weight: 800;
        padding: 0;
      }

      &[data-icon-position="left"] {
        padding-left: 4px;

        .icon {
          margin-right: 6px;
        }
      }

      &[data-icon-position="right"] {
        padding-right: 4px;

        .icon {
          margin-left: 6px;
        }
      }

      &[data-icon-position="null"] {
        padding: 4px 12px;
        .button-text {
          font-size: 10px;
        }
      }
    }

    &[data-size="mini"] {
      padding: 4px 13px;
      border-radius: 45px;

      .icon {
        width: 28px;
        height: 28px;
        min-width: 28px;
        min-height: 28px;
      }

      .button-text {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        padding: 4px 0;
      }

      &[data-icon-position="left"] {
        padding-left: 4px;

        .icon {
          margin-right: 8px;
        }
      }

      &[data-icon-position="right"] {
        padding-right: 4px;

        .icon {
          margin-left: 8px;
        }
      }

      &[data-icon-position="null"] {
        padding: 4px 18px;
        .button-text {
          font-size: 15px;
        }
      }
    }

    &[data-size="tiny"] {
      padding: 6px 18px;
      border-radius: 45px;

      .icon {
        width: 36px;
        height: 36px;
        min-width: 36px;
        min-height: 36px;
      }

      .button-text {
        font-size: 20px;
        line-height: 25px;
        font-weight: 800;
        padding: 5px 0;
      }

      &[data-icon-position="left"] {
        padding-left: 6px;

        .icon {
          margin-right: 10px;
        }
      }

      &[data-icon-position="right"] {
        padding-right: 6px;

        .icon {
          margin-left: 10px;
        }
      }

      &[data-icon-position="null"] {
        padding: 6px 24px;
        .button-text {
          font-size: 18px;
        }
      }
    }

    &[data-size="small"] {
      padding: 8px 30px;
      border-radius: 45px;

      .icon {
        width: 60px;
        height: 60px;
        min-width: 60px;
        min-height: 60px;
      }

      .button-text {
        font-size: 30px;
        line-height: 35px;
        font-weight: 800;
        padding: 12px 0;
      }

      &[data-icon-position="left"] {
        padding-left: 8px;

        .icon {
          margin-right: 18px;
        }
      }

      &[data-icon-position="right"] {
        padding-right: 8px;

        .icon {
          margin-left: 18px;
        }
      }

      &[data-icon-position="null"] {
        padding: 8px 36px;
        .button-text {
          font-size: 26px;
        }
      }
    }

    &[data-size="regular"] {
      padding: 16px 30px;
      border-radius: 100px;

      .icon {
        width: 98px;
        height: 98px;
        min-width: 98px;
        min-height: 98px;
      }

      .button-text {
        font-size: 40px;
        line-height: 45px;
        font-weight: 800;
        padding: 26px 0;
      }

      &[data-icon-position="left"] {
        padding-left: 16px;

        .icon {
          margin-right: 22px;
        }
      }

      &[data-icon-position="right"] {
        padding-right: 16px;

        .icon {
          margin-left: 22px;
        }
      }

      &[data-icon-position="null"] {
        padding: 16px 40px;
        .button-text {
          font-size: 34px;
        }
      }
    }

    &[data-size="large"] {
      padding: 16px 40px;
      border-radius: 100px;

      .icon {
        height: 125px;
        width: 125px;
        min-width: 125px;
        min-height: 125px;
      }

      .button-text {
        font-family: FatFrank, sans-serif;
        font-size: 50px;
        line-height: 55px;
        font-weight: 900;
        padding: 35px 0;
      }

      &[data-icon-position="left"] {
        padding-left: 16px;

        .icon {
          margin-right: 26px;
        }
      }

      &[data-icon-position="right"] {
        padding-right: 16px;

        .icon {
          margin-left: 26px;
        }
      }

      &[data-icon-position="null"] {
        padding: 16px 50px;
        .button-text {
          font-size: 40px;
        }
      }
    }

    &[data-size="xlarge"] {
      padding: 16px 70px;
      border-radius: 100px;

      .icon {
        height: 150px;
        width: 150px;
        min-width: 150px;
        min-height: 150px;
      }

      .button-text {
        font-family: FatFrank, sans-serif;
        font-size: 60px;
        line-height: 65px;
        font-weight: 900;
        padding: 42px 0;
      }

      &[data-icon-position="left"] {
        padding-left: 16px;

        .icon {
          margin-right: 30px;
        }
      }

      &[data-icon-position="right"] {
        padding-right: 16px;

        .icon {
          margin-left: 30px;
        }
      }

      &[data-icon-position="null"] {
        padding: 16px 80px;
        .button-text {
          font-size: 50px;
        }
      }
    }

    &.icon-no-bg {
      .icon {
        margin: 0 !important;
      }

      &[data-size="micro"] {
        .icon {
          padding: 4px;
        }
        .button-text {
          font-size: 10px;
        }
      }

      &[data-size="mini"] {
        .icon {
          padding: 8px;
        }
        .button-text {
          font-size: 15px;
        }
      }

      &[data-size="tiny"] {
        .icon {
          padding: 10px;
        }
        .button-text {
          font-size: 18px;
        }
      }

      &[data-size="small"] {
        .icon {
          padding: 18px;
        }
        .button-text {
          font-size: 26px;
        }
      }

      &[data-size="regular"] {
        .icon {
          padding: 28px;
        }
        .button-text {
          font-size: 34px;
        }
      }

      &[data-size="large"] {
        .icon {
          padding: 30px;
        }
        .button-text {
          font-size: 40px;
        }
      }

      &[data-size="xlarge"] {
        .icon {
          padding: 40px;
        }
        .button-text {
          font-size: 50px;
        }
      }
    }

    &[data-size="responsive"] {
      // mini range
      @media only screen and (max-width: 599px) {
        padding: 4px 13px;
        border-radius: 45px;

        .icon {
          width: 28px;
          height: 28px;
          min-width: 28px;
          min-height: 28px;
        }

        .button-text {
          font-size: 16px;
          line-height: 20px;
          font-weight: 600;
          padding: 4px 0;
        }

        &[data-icon-position="left"] {
          padding-left: 4px;

          .icon {
            margin-right: 8px;
          }
        }

        &[data-icon-position="right"] {
          padding-right: 4px;

          .icon {
            margin-left: 8px;
          }
        }

        &[data-icon-position="null"] {
          padding: 4px 18px;
          .button-text {
            font-size: 15px;
          }
        }

        &.icon-no-bg {
          .icon {
            margin: 0 !important;
            padding: 8px;
          }
          .button-text {
            font-size: 15px;
          }
        }
      }

      // tiny range (max size allowed when data-max-size="tiny")
      @media only screen and (min-width: 600px) {
        padding: 6px 18px;
        border-radius: 45px;

        .icon {
          width: 36px;
          height: 36px;
          min-width: 36px;
          min-height: 36px;
        }

        .button-text {
          font-size: 20px;
          line-height: 25px;
          font-weight: 800;
          padding: 5px 0;
        }

        &[data-icon-position="left"] {
          padding-left: 6px;

          .icon {
            margin-right: 10px;
          }
        }

        &[data-icon-position="right"] {
          padding-right: 6px;

          .icon {
            margin-left: 10px;
          }
        }

        &[data-icon-position="null"] {
          padding: 6px 24px;
          .button-text {
            font-size: 18px;
          }
        }
        &.icon-no-bg {
          .icon {
            margin: 0 !important;
            padding: 10px;
          }
          .button-text {
            font-size: 18px;
          }
        }
      }

      // small range (max size allowed when data-max-size="small")
      &:not([data-max-size="tiny"]) {
        @media only screen and (min-width: 1200px) {
          padding: 8px 30px;
          border-radius: 45px;

          .icon {
            width: 60px;
            height: 60px;
            min-width: 60px;
            min-height: 60px;
          }

          .button-text {
            font-size: 30px;
            line-height: 35px;
            font-weight: 800;
            padding: 12px 0;
          }

          &[data-icon-position="left"] {
            padding-left: 8px;

            .icon {
              margin-right: 18px;
            }
          }

          &[data-icon-position="right"] {
            padding-right: 8px;

            .icon {
              margin-left: 18px;
            }
          }

          &[data-icon-position="null"] {
            padding: 8px 36px;
            .button-text {
              font-size: 26px;
            }
          }

          &.icon-no-bg {
            .icon {
              margin: 0 !important;
              padding: 18px;
            }
            .button-text {
              font-size: 26px;
            }
          }
        }
      }

      // regular range (max size allowed)
      &:not([data-max-size="tiny"]):not([data-max-size="small"]) {
        @media only screen and (min-width: 1600px) {
          padding: 16px 30px;
          border-radius: 100px;

          .icon {
            width: 98px;
            height: 98px;
            min-width: 98px;
            min-height: 98px;
          }

          .button-text {
            font-size: 40px;
            line-height: 45px;
            font-weight: 800;
            padding: 26px 0;
          }

          &[data-icon-position="left"] {
            padding-left: 16px;

            .icon {
              margin-right: 22px;
            }
          }

          &[data-icon-position="right"] {
            padding-right: 16px;

            .icon {
              margin-left: 22px;
            }
          }

          &[data-icon-position="null"] {
            padding: 16px 40px;
            .button-text {
              font-size: 34px;
            }
          }

          &.icon-no-bg {
            .icon {
              margin: 0 !important;
              padding: 28px;
            }
            .button-text {
              font-size: 34px;
            }
          }
        }
      }
    }
  }

  // Button with no icon
  &.pill-alt {
    position: relative;
    font-size: 20px;
    opacity: 1;
    border-radius: 45px;
    transition: 250ms all;
    background: none;
    border: none;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: @color2;
      border: 2px solid @border;
      border-radius: inherit;
      z-index: 10;
    }

    .button-text {
      color: @color1;
      font-weight: normal;
      z-index: 11;
    }

    &:hover,
    &.selected {
      &::before {
        background: @border;
      }

      .button-text {
        color: @hovertxt;
      }
    }

    &.disabled,
    &.invalid {
      color: var(--disabled-text);
      background-color: var(--disabled);
      border-color: var(--disabled-border);
      font-weight: lighter;
      pointer-events: none;
      filter: grayscale(1);
    }

    &.waiting {
      animation: waitingButtonAnimation 1750ms alternate infinite ease-in-out;
    }

    &[data-size="small"] {
      padding: 15px 40px;
      border-radius: 100px;

      .button-text {
        font-size: 18px;
        line-height: 20px;
        font-weight: 800;
      }
    }
  }

  // Icon only button with no text
  &.round {
    border-radius: 60px;
    opacity: 0.7;
    border: none;
    background: transparent;
    display: flex;

    .icon,
    svg {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover,
    &.selected {
      opacity: 1;
    }

    &.inactive {
      pointer-events: none;
      color: var(--disabled-text);
      filter: grayscale(1);
    }

    &.disabled {
      pointer-events: none;

      .icon {
        filter: saturate(0);
      }
    }

    &[data-size="regular"] {
      width: 50px;
      height: 50px;
      opacity: 0.8;

      &.active {
        opacity: 1;
      }
    }

    &[data-size="small"] {
      width: 40px;
      height: 40px;

      &.active {
        width: 52px;
        height: 52px;
        opacity: 1;
      }
    }

    &[data-size="tiny"] {
      width: 33px;
      height: 33px;

      &.active {
        width: 45px;
        height: 45px;
        opacity: 1;
      }
    }

    &[data-size="mini"] {
      width: 25px;
      height: 25px;

      &.active {
        width: 25px;
        height: 25px;
        opacity: 1;
      }
    }
  }

  // Special case button for navs
  &.nav {
    flex-wrap: nowrap;

    svg {
      color: var(--troubadour-main);
    }

    .icon,
    svg {
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .button-text {
      color: @color2;
      font-weight: bold;
    }

    &:hover {
      text-decoration: underline;
    }

    &[data-icon-position="right"] {
      &[data-size="regular"] {
        .icon {
          margin-left: 15px;
        }
      }

      &[data-size="small"] {
        .icon {
          margin-left: 10px;
        }
      }

      &[data-size="tiny"] {
        .icon {
          margin-left: 5px;
        }
      }

      &[data-size="mini"] {
        .icon {
          margin-left: 5px;
        }
      }

      .button-text {
        text-align: right;
      }
    }

    &[data-icon-position="left"] {
      &[data-size="regular"] {
        .icon {
          margin-right: 15px;
        }
      }

      &[data-size="small"] {
        .icon {
          margin-right: 10px;
        }
      }

      &[data-size="tiny"] {
        .icon {
          margin-right: 5px;
        }
      }

      &[data-size="mini"] {
        .icon {
          margin-right: 5px;
        }
      }

      .button-text {
        text-align: left;
      }
    }

    //SIZE STYLING
    &[data-size="large"] {
      padding: 5px;
      border-radius: 100px;

      .button-text {
        padding: 0 15px;
        font-size: 30px;
        line-height: 36px;
      }

      .icon {
        height: 80px;
      }
    }

    &[data-size="regular"] {
      padding: 20px 31px;

      .icon {
        height: 23px;
        width: auto;
      }

      .button-text {
        font-size: 20px;
        line-height: 22.5px;
      }
    }

    &[data-size="small"] {
      padding: 10px;

      .icon {
        height: 16px;
        width: auto;
      }

      .button-text {
        font-size: 20px;
        line-height: 20px;
      }
    }

    &[data-size="tiny"] {
      padding: 15px 20px;

      .icon {
        height: 10px;
        width: auto;
      }

      .button-text {
        font-size: 12px;
        line-height: 14px;
      }
    }

    &[data-size="mini"] {
      padding: 4px 12px;

      .icon {
        height: 5px;
        width: auto;
      }

      .button-text {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}

.troubadour-button-login-template(@color1: var(--default-bg), @color2: var(--default-color), @border: var(--button-border)) {
  .troubadour-button-template(@color1, @color2, @border);

  border: none !important;
  box-shadow: var(--dropshadow);
}
