@font-face {
  font-family: Eagle;
  src: url("../../fonts/eagle-book-webfont.woff2") format("woff2"),
    url("../../fonts/eagle-book-webfont.woff") format("woff"),
    url("../../fonts/eagle-book.ttf") format("truetype");
}

@font-face {
  font-family: "Sofia Pro";
  src: url("../../fonts/sofiapro-regular-webfont.woff2") format("woff2"),
    url("../../fonts/sofiapro-regular-webfont.woff") format("woff"),
    url("../../fonts/sofiapro-regular.ttf") format("truetype");
}

@font-face {
  font-family: "Sofia Pro";
  src: url("../../fonts/sofiapro-bold-webfont.woff2") format("woff2"),
    url("../../fonts/sofiapro-bold-webfont.woff") format("woff"),
    url("../../fonts/sofiapro-bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../../fonts/sofiapro-regularitalic-webfont.woff2") format("woff2"),
    url("../../fonts/sofiapro-regularitalic-webfont.woff") format("woff"),
    url("../../fonts/sofiapro-regularitalic.ttf") format("truetype");
  font-style: italic;
}
//fonts
@title-font: Eagle, Lato, sans-serif;
@reading-font: "Sofia Pro", Lato, sans-serif;

h1 {
  font-family: Eagle, Lato, sans-serif;
  font-weight: bold;
  font-size: 3em;
  text-transform: uppercase;
}

h2 {
  font-family: "Sofia Pro", Lato, sans-serif;
  font-weight: 600;
  font-size: 2em;
  text-transform: uppercase;
}

h3 {
  font-family: "Sofia Pro", Lato, sans-serif;
  font-weight: 600;
  font-size: 1.75em;
}

h4 {
  font-family: Eagle, Lato, sans-serif;
  font-weight: 600;
  font-size: 1.5em;
  letter-spacing: 0.04em;
}

h5 {
  font-family: "Sofia Pro", Lato, sans-serif;
  font-weight: 400;
  font-size: 1.2em;
}

h6 {
  font-family: "Sofia Pro", Lato, sans-serif;
  font-weight: 400;
  font-size: 1.1em;
  letter-spacing: 0;
}

p {
  font-family: "Sofia Pro", Lato, sans-serif;
  font-weight: 400;
  font-size: 1em;
}

small {
  font-family: "Sofia Pro", Lato, sans-serif;
  font-weight: normal;
  font-size: 0.75em;
  letter-spacing: 0.08em;
}
