.form-check {
  display: flex;
  align-items: center;
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="email"] {
  border: 2px solid var(--default-color-5);
  width: 100%;
  border-radius: 6px;
  background-color: var(--default-bg);
  color: var(--default-color);
  font-size: 16px;
  padding-left: 10px;
  height: 42px;

  &::placeholder {
    opacity: 0.4;
  }

  &:disabled {
    border-color: var(--border) !important;
  }
}

select {
  border: 2px solid var(--default-color-5);
  width: 100%;
  padding: 5px !important;
  border-radius: 6px !important;
  background-color: var(--default-bg) !important;
  color: var(--default-color);
  font-size: 18px;
  height: 42px !important;

  &::placeholder {
    opacity: 0.4;
  }

  &:disabled {
    border-color: var(--border) !important;
  }
}

textarea {
  border: 2px solid var(--default-color-5);
  width: 100%;
  border-radius: 6px;
  background-color: var(--default-bg) !important;
  color: var(--default-color);
  font-size: 18px;
  padding: 10px;

  &::placeholder {
    opacity: 0.4;
  }

  &:disabled {
    border-color: var(--border) !important;
    color: var(--default-color);
    font-style: italic;
  }
}

input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  border: 2px solid var(--primary-text-1);
  transition: 0.2s all linear;
  padding: 6px;
  border-radius: 4px;

  &:checked {
    background: var(--primary-text-1);
  }

  & ~ label {
    color: var(--primary-text-1);
    font-size: 14px;
    padding: 6px;
    margin: 0;

    &:hover {
      cursor: pointer;
    }
  }

  &:disabled {
    cursor: unset !important;
    border-color: var(--border) !important;

    & ~ label {
      color: var(--primary-text-1);
      opacity: 0.8;
      cursor: default !important;
    }
  }

  &:checked {
    background-color: var(--primary-text-1);

    &:disabled {
      background-color: var(--disabled) !important;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
