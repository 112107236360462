/* region Webkits */

.keyframes(@name;
@args) {
  @-webkit-keyframes @name {
    @args();
  }
  @keyframes @name {
    @args();
  }
}

.animation(@args) {
  -webkit-animation: @args;
  animation: @args;
}

.animation-delay(@delay) {
  -webkit-animation-delay: @delay;
  animation-delay: @delay;
}

.animation-direction(@direction) {
  -webkit-animation-direction: @direction;
  -o-animation-direction: @direction;
}

.animation-duration(@duration) {
  -webkit-animation-duration: @duration;
  -o-animation-duration: @duration;
}

.animation-fill-mode(@mode) {
  -webkit-animation-fill-mode: @mode;
  animation-fill-mode: @mode;
}

.animation-iteration-count(@count) {
  -webkit-animation-iteration-count: @count;
  animation-iteration-count: @count;
}

.animation-name(@name) {
  -webkit-animation-name: @name;
  animation-name: @name;
}

.animation-play-state(@state) {
  -webkit-animation-play-state: @state;
  animation-play-state: @state;
}

.animation-timing-function(@function) {
  -webkit-animation-timing-function: @function;
  animation-timing-function: @function;
}

// Background Size
.background-size(@args) {
  background-size: @args;
}

// Border Radius
.border-radius(@args) {
  border-radius: @args;
  background-clip: padding-box;
}

// Box Shadows
.box-shadow(@args) {
  -webkit-box-shadow: @args;
  box-shadow: @args;
}

.inner-shadow(@args) {
  .box-shadow(inset @args);
}

// Box Sizing
.box-sizing(@args) {
  -webkit-box-sizing: @args;
  -moz-box-sizing: @args;
  box-sizing: @args;
}

.border-box() {
  .box-sizing(order-box);
}

.content-box() {
  .box-sizing(ontent-box);
}

// Columns
.columns(@args) {
  -webkit-columns: @args;
  -moz-columns: @args;
  columns: @args;
}

.column-count(@count) {
  -webkit-column-count: @count;
  -moz-column-count: @count;
  column-count: @count;
}

.column-gap(@gap) {
  -webkit-column-gap: @gap;
  -moz-column-gap: @gap;
  column-gap: @gap;
}

.column-width(@width) {
  -webkit-column-width: @width;
  -moz-column-width: @width;
  column-width: @width;
}

.column-rule(@args) {
  -webkit-column-rule: @args;
  -moz-column-rule: @args;
  column-rule: @args;
}

// Filter
.filter(@args) {
  -webkit-filter: @args;
  filter: @args;
}

// Gradients
.gradient(@default: #F5F5F5, @start: #EEE, @stop: #FFF) {
  .linear-gradient-top(@default, @start, 0%, @stop, 100%);
}

.linear-gradient-top(@default, @color1, @stop1, @color2, @stop2) {
  background-color: @default;
  background-image: -webkit-linear-gradient(
    top,
    @color1 @stop1,
    @color2 @stop2
  );
  background-image: linear-gradient(top, @color1 @stop1, @color2 @stop2);
}

.linear-gradient-top(@default, @color1, @stop1, @color2, @stop2, @color3, @stop3) {
  background-color: @default;
  background-image: -webkit-linear-gradient(
    top,
    @color1 @stop1,
    @color2 @stop2,
    @color3 @stop3
  );
  background-image: linear-gradient(
    top,
    @color1 @stop1,
    @color2 @stop2,
    @color3 @stop3
  );
}

.linear-gradient-top(@default, @color1, @stop1, @color2, @stop2, @color3, @stop3, @color4, @stop4) {
  background-color: @default;
  background-image: -webkit-linear-gradient(
    top,
    @color1 @stop1,
    @color2 @stop2,
    @color3 @stop3,
    @color4 @stop4
  );
  background-image: linear-gradient(
    top,
    @color1 @stop1,
    @color2 @stop2,
    @color3 @stop3,
    @color4 @stop4
  );
}

.linear-gradient-left(@default, @color1, @stop1, @color2, @stop2) {
  background-color: @default;
  background-image: -webkit-linear-gradient(
    left,
    @color1 @stop1,
    @color2 @stop2
  );
  background-image: linear-gradient(left, @color1 @stop1, @color2 @stop2);
}

.linear-gradient-left(@default, @color1, @stop1, @color2, @stop2, @color3, @stop3) {
  background-color: @default;
  background-image: -webkit-linear-gradient(
    left,
    @color1 @stop1,
    @color2 @stop2,
    @color3 @stop3
  );
  background-image: linear-gradient(
    left,
    @color1 @stop1,
    @color2 @stop2,
    @color3 @stop3
  );
}

.linear-gradient-left(@default, @color1, @stop1, @color2, @stop2, @color3, @stop3, @color4, @stop4) {
  background-color: @default;
  background-image: -webkit-linear-gradient(
    left,
    @color1 @stop1,
    @color2 @stop2,
    @color3 @stop3,
    @color4 @stop4
  );
  background-image: linear-gradient(
    left,
    @color1 @stop1,
    @color2 @stop2,
    @color3 @stop3,
    @color4 @stop4
  );
}

// UserSelect
.user-select(@select: auto) {
  -webkit-user-select: @select;
  -moz-user-select: @select;
  -ms-user-select: @select;
  user-select: @select;
}

// Opacity
.opacity(@factor) {
  opacity: @factor;
}

// Text Shadow
.text-shadow(@args) {
  text-shadow: @args;
}

// Transforms
.transform(@args) {
  -webkit-transform: @args;
  -ms-transform: @args;
  transform: @args;
}

.transform-origin(@args) {
  -webkit-transform-origin: @args;
  -ms-transform-origin: @args;
  transform-origin: @args;
}

.transform-style(@style) {
  -webkit-transform-style: @style;
  -ms-transform-style: @style;
  transform-style: @style;
}

.rotate(@deg: 45deg) {
  .transform(rotate(@deg));
}

.scale(@factor: 0.5) {
  .transform(scale(@factor));
}

.translate(@x, @y) {
  .transform(translate(@x, @y));
}

.translateX(@x) {
  .transform(traslateX(@x));
}

.translateY(@y) {
  .transform(translateY(@y));
}

.translate3d(@x, @y, @z) {
  .transform(translate3d(@x, @y, @z));
}

.translateHardware(@x, @y) {
  .translate(@x, @y);
  -webkit-transform: translate3d(@x, @y, 0);
  -o-transform: translate3d(@x, @y, 0);
  transform: translate3d(@x, @y, 0);
}

// Transitions
.transition(@args: 200ms) {
  transition: @args;
}

.transition-delay(@delay: 0) {
  transition-delay: @delay;
}

.transition-duration(@duration: 200ms) {
  transition-duration: @duration;
}

.transition-property(@property: all) {
  transition-property: @property;
}

.transition-timing-function(@function: ease) {
  transition-timing-function: @function;
}

// Flexbox
.flex-block() {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.flex-inline() {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}

.flex-flow(@direction: row, @wrap: nowrap) {
  -moz-flex-flow: @direction @wrap;
  -ms-flex-direction: @direction;
  -ms-flex-wrap: @wrap;
  -webkit-flex-flow: @direction @wrap;
  flex-flow: @direction @wrap;
}

.align-content(@alignment) {
  -moz-align-content: @alignment;
  -ms-align-content: @alignment;
  -webkit-align-content: @alignment;
  align-content: @alignment;
}

.flex-direction(@direction: row) {
  -webkit-flex-direction: @direction;
  -moz-flex-direction: @direction;
  -ms-flex-direction: @direction;
  flex-direction: @direction;
}

.flex-wrap(@wrap: nowrap) {
  -webkit-flex-wrap: @wrap;
  -moz-flex-wrap: @wrap;
  -ms-flex-wrap: @wrap;
  flex-wrap: @wrap;
}

.justify-content(@justification) {
  -webkit-box-pack: @justification;
  -moz-box-pack: @justification;
  -ms-flex-pack: replace(@justification, "flex-", "");
  -webkit-justify-content: @justification;
  justify-content: @justification;
}

.align-items(@mode) {
  -webkit-box-align: @mode;
  -moz-box-align: @mode;
  -ms-flex-align: @mode;
  -webkit-align-items: @mode;
  align-items: @mode;
}

.flex(@args: none) {
  -moz-flex: @args;
  -ms-flex: @args;
  -webkit-flex: @args;
  flex: @args;
}

.order(@order: 0) {
  -webkit-box-ordinal-group: @order;
  -moz-box-ordinal-group: @order;
  -ms-box-ordinal-group: @order;
  -ms-flex-order: @order;
  -webkit-order: @order;
  -moz-order: @order;
  -ms-order: @order;
  order: @order;
}

.flex-grow(@grow: 1) {
  -moz-flex-grow: @grow;
  -ms-flex-grow: @grow;
  -webkit-flex-grow: @grow;
  flex-grow: @grow;
}

.flex-shrink(@shrink: 1) {
  -moz-flex-shrink: @shrink;
  -ms-flex-shrink: @shrink;
  -webkit-flex-shrink: @shrink;
  flex-shrink: @shrink;
}

.flex-basis(@basis: auto) {
  -moz-flex-basis: @basis;
  -ms-flex-basis: @basis;
  -webkit-flex-basis: @basis;
  flex-basis: @basis;
}

.align-self(@align: auto) {
  -moz-align-self: @align;
  -ms-align-self: @align;
  -webkit-align-self: @align;
  align-self: @align;
}

/*endregion webkits*/
