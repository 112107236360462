

.action-popup {
  position: fixed;
  left: 50%;
  margin-left: -50px;
  top: 90%;
  opacity: 1;
  z-index: 400;
  transition: all 0.3s ease;
  border-radius: 10px;
  justify-content: left;
  align-items: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  background-color: var(--default-bg);
  padding: 10px 30px;
  display: none !important;

  .delete-button,
  .reject-button {
    margin: 0 10px;
  }

  .confirm-button {
    margin: 10px;
  }
}

.display-action-popup {
  display: flex !important;
}
