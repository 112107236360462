.navigation-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  display: flex;
  flex-direction: column;
  pointer-events: none; //Do not remove or the page will not be interactable

  ul:not(.dropdown-menu) {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
  }

  //Global styling
  nav {
    background-color: var(--default-bg);
    pointer-events: initial;

    .admin-side-menu,
    .lower-section {
      width: 100%;
    }

    .submenu-container {
      flex-direction: column;
      border: 2px solid var(--top-nav-separator);
      border-bottom: none;
      position: absolute;
      top: 80px;
      left: 150px;
      height: calc(100vh - 80px);
      width: 250px;
      background-color: var(--default-bg);
      display: none;

      @media screen and (min-width: 1081px) {
        height: calc(100vh - 40px);
        top: 40px;
      }

      &.opened {
        display: flex;
      }

      .submenu {
        width: 100%;
        height: 100%;
        flex-direction: column;
        display: none;
        padding: 15px;

        .submenu-link {
          font-size: 20px;
          font-weight: bolder;
          line-height: 20px;
          color: var(--default-color);
          padding: 15px;
          margin-bottom: 15px;
          cursor: pointer;
        }

        &.active {
          display: flex;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .submenu-close-icon {
        align-self: flex-end;
        width: 34px;
        height: 34px;
        margin: 0 15px 15px 0;
        cursor: pointer;
      }
    }

    .navigation-link {
      position: relative;
      display: flex;
      align-items: center;
      font-family: "Sofia Pro", sans-serif;
      font-size: 20px;
      line-height: 20px;
      font-weight: bolder;
      text-transform: initial;
      color: var(--nav-primary-link);
      cursor: pointer;

      &:hover,
      &:active {
        text-decoration: underline;
      }
    }

    .login-button {
      background-color: var(--top-nav-heading) !important;

      a {
        color: var(--default-color-2) !important;

        .account-creation-text {
          font-size: 12px;
          font-weight: 500;
          white-space: nowrap;

          @media screen and (max-width: 767px) {
            display: none;
          }
        }
      }
    }

    // when displaying troub nav on admin pages
    .troubadour-side-menu {
      .navigation-link {
        color: var(--nav-primary-link-troub);
      }
    }

    // when displaying troub nav on admin pages
    .family-side-menu {
      .navigation-link {
        color: var(--nav-primary-link-troub);
      }
    }

    // when displaying troub nav on admin pages
    .student-side-menu {
      .student-name {
        color: var(--troubadour-main);
        padding: 20px;
        font-size: 18px;
        line-height: 15px;
        font-weight: 800;
        margin: 0;
        text-align: center;
        text-transform: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .nav-header {
        background-color: var(--troubadour-main-15);
        border-bottom: 1px solid var(--top-nav-separator);
      }
      .navigation-link {
        color: var(--nav-primary-link-troub);
      }
    }

    // when displaying const nav on admin pages
    .constellation-side-menu {
      .navigation-link {
        color: var(--nav-primary-link-const);
      }
    }

    .nav-dropdown,
    .nav-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 30px;
      height: 100%;
      border-right: 2px solid var(--top-nav-separator);

      &:last-child {
        border-right: none;
      }
    }

    .nav-button {
      position: relative;

      &.submenu-arrow {
        overflow: visible;
        position: static;

        &::after {
          content: "";
          position: absolute;
          left: 150px;
          width: 12px;
          height: 22px;
          background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/icons/menu-indicator.png");
          z-index: 300;

          @media screen and (max-width: 1080px) {
            left: 150px;
          }

          @media screen and (max-width: 768px) {
            left: auto;
            right: 170px;
            transform: rotate(180deg);
          }
        }
      }

      &.menu-item-highlight {
        // for tutorials
        // menu item needs to appear over a semi-trans page mask
        background-color: var(--default-bg);
        z-index: 1000;
      }
    }

    &.top-menu {
      position: relative;
      height: 100px;
      display: flex;
      padding: 0;
      box-shadow: var(--dropshadow);

      .logo-container {
        display: flex;
        align-items: center;
        height: 100%;
        padding-left: 10px;

        .logo-container-inner {
          display: flex;
          flex: 1;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          height: 100% !important;
        }

        .nav-logo {
          height: 100%;
          max-width: 100%;
          min-width: 160px;
          padding: 5px 0;
          display: none;

          @media screen and (max-width: 599px) {
            max-width: 150px;
          }
        }

        .nanomonx-link,
        .troub-link {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: auto;
          height: 100%;
        }

        .nanomonx-logo {
          min-width: auto;
          height: 80%;
          padding: 5px 0;

          @media screen and (min-width: 601px) {
            max-width: 375px;
            padding: 5px 10px;
          }
        }

        .nanomonx-presents-heading {
          text-transform: lowercase;
          font-size: 12px;
          line-height: 18px;
          font-weight: 500;
          color: var(--top-nav-presents);
          padding: 0;
          margin: 0;
          align-self: center;
          transform: rotate(-90deg);

          @media screen and (min-width: 801px) {
            font-size: 18px;
            align-self: flex-end;
            margin: 0 0 10px;
            padding: 0 10px;
            transform: rotate(0);
          }

          @media screen and (min-width: 1201px) {
            font-size: 30px;
            line-height: 30px;
            padding: 0 20px;
          }
        }

        .admin-manage-heading {
          width: auto;
          max-width: 30vw;
          margin: 0;
          padding: 10px;
          font-size: clamp(12px, 2vw, 16px);
          line-height: 1.2em;
          font-weight: 400;
          color: var(--top-nav-heading);
          text-transform: none;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .top-menu-nav-links {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        gap: 10px 30px;

        a {
          color: var(--troubadour-main-5);
          font-size: clamp(12px, 1.5vw, 20px);
          margin: 0;
          text-decoration: underline;
          white-space: nowrap;

          img {
            width: 20px;
          }
        }
      }

      .top-menu-dropdown {
        &:last-of-type {
          .dropdown-button {
            margin-right: 0;
          }
        }

        .dropdown-button {
          align-items: center;
          color: var(--troubadour-main-5);
          font-size: clamp(14px, 2vw, 20px);
          font-weight: 800;
          line-height: 1.4em;
          background: none;
          border: none;
          box-shadow: none;
          user-select: none;
          margin: 0;
          padding: 6px 10px;

          .icon {
            width: 25px;
            margin: 0 10px 0 0;
          }

          span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            @media screen and (max-width: 800px) {
              max-width: 15vw;
            }
          }

          svg {
            width: 15px;
            color: var(--troubadour-main);
          }

          &:hover {
            text-decoration: none;
            background-color: var(--default-bg-2);
          }

          &.show {
            background-color: var(--troubadour-main-16);
          }
        }

        .dropdown-menu {
          padding: 10px;
          border: none;
          box-shadow: var(--dropshadow);
          gap: 10px;

          .nav-button {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            margin: 0 0 10px;
            height: 100%;
            border: none;

            &:last-of-type {
              margin-bottom: 0;
            }

            .navigation-link {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              width: 100%;
              padding: 10px;
              height: auto;
              min-height: auto;
              border-radius: 8px;
              text-decoration: none;
              gap: 0;

              img {
                aspect-ratio: 1 / 1;
                width: 32px;
              }

              span {
                color: var(--default-color-3);
                font-size: 20px;
                font-weight: 400;
                line-height: normal;
                white-space: nowrap;
                opacity: 1;

                &::before {
                  display: block;
                  content: attr(data-text);
                  font-weight: 800;
                  height: 0;
                  overflow: hidden;
                  visibility: hidden;
                }
              }

              &.active,
              &:hover {
                background-color: var(--default-bg-2);
                span {
                  font-weight: 800;
                }
              }
            }
          }
        }
      }

      .action-section {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        list-style-type: none;

        .greeting-container {
          display: flex;
          flex-flow: row nowrap;
          height: 100%;
          gap: 30px;

          .greeting a {
            font-family: "Sofia Pro", sans-serif;
            font-size: 16px;
            color: var(--top-nav-greeting);
            text-decoration: underline;
            white-space: nowrap;

            &:hover {
              opacity: 0.8;
            }
          }

          .greeting div {
            font-family: "Sofia Pro", sans-serif;
            font-size: 16px;
            color: var(--top-nav-greeting);
            white-space: nowrap;
          }
        }

        .username {
          color: var(--default-color-3);
          font-size: 16px;
          font-weight: 700;
          line-height: normal;
          opacity: 0.5;

          .open-tutorial-info-btn {
            margin-right: 20px;
          }
        }
      }

      .freemium-mode {
        font-size: clamp(12px, 2vw, 16px);
        color: var(--troubadour-main-7);
        font-weight: 800;
        white-space: nowrap;

        .get-subscription-btn {
          color: var(--troubadour-main-7);
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;
          text-decoration: underline;
          gap: 10px;

          &::before {
            content: "";
            width: 30px;
            height: 30px;
            background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/icons/troub-subscriptions.svg");
            background-size: cover;
            background-repeat: no-repeat;
          }

          @media screen and (max-width: 768px) {
            gap: 5px;

            &::before {
              width: 20px;
              height: 20px;
            }
          }
        }
      }

      .open-tutorial-info-btn {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }

      .nav-button,
      .nav-dropdown {
        i,
        img {
          margin-right: 7px;
        }
      }

      &.top-menu--compact {
        height: 40px;

        .logo-container {
          .nanomonx-logo {
            height: 100%;
          }
        }

        .nanomonx-presents-heading {
          align-self: center;
          font-size: 14px;
          line-height: 14px;
          padding: 0 0 0 5px;
          margin: 0;
          transform: rotate(0);
        }
      }

      &.top-menu--no-sidebar {
        .action-section {
          margin-right: 20px;
        }
      }

      &.top-menu--teacher {
        &.has-correction-bar {
          height: 88px;
        }
      }
    }

    &.side-menu {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      flex: 1;
      width: 150px;
      box-shadow: var(--side-menu-dropshadow);
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--primary-text-2);
        border-radius: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
        border-radius: 5px;
      }

      @supports not selector(::-webkit-scrollbar) {
        scrollbar-color: var(--primary-text-2) transparent;
        scrollbar-width: thin;
      }

      ul {
        width: 100%;
        flex-direction: column;
      }

      .logo-container {
        width: 100%;

        a {
          display: none;

          .nav-logo {
            width: 100%;
            max-height: 150px;
            padding: 10px;
          }
        }
      }

      .nav-button {
        flex-direction: column;
        text-align: center;
        border-right: none;
        border-bottom: 1px solid var(--top-nav-separator);
        padding: 0;
        opacity: 0.8;

        &:first-of-type {
          border-top: 1px solid var(--top-nav-separator);
        }

        img {
          margin-bottom: 2px;
        }

        &.active {
          opacity: 1;
        }
      }

      .navigation-link {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 5px 5px 5px 10px;
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        height: 58px;
        min-height: 58px;
        gap: 10px;

        img {
          width: 35px;
        }

        span {
          text-align: left;
          margin-bottom: 3px;
        }

        &.active,
        &:hover {
          background-color: var(--nav-sidemenu-link-hover-bg);
        }
      }

      // when displaying troub nav on admin pages
      .troubadour-side-menu {
        .navigation-link {
          &.active,
          &:hover {
            background-color: var(--nav-sidemenu-link-troub-hover-bg);
          }
        }
      }

      // when displaying family nav on admin pages
      .family-side-menu {
        .navigation-link {
          &.active,
          &:hover {
            background-color: var(--default-bg-2);
          }
        }
      }

      // when displaying const nav on admin pages
      .constellation-side-menu {
        .navigation-link {
          &.active,
          &:hover {
            background-color: var(--nav-sidemenu-link-const-hover-bg);
          }
        }
      }

      .lower-section {
        margin-top: auto;
        padding-top: 40px;

        .navigation-link {
          color: var(--nav-lower-primary-link);

          &.active,
          &:hover {
            background-color: var(--nav-sidemenu-lower-link-hover-bg);
          }
        }

        &[data-product="FAMILY"] {
          .navigation-link {
            color: var(--troubadour-main);

            &.active,
            &:hover {
              background-color: var(--default-bg-2);
            }
          }
        }
      }

      .language-switcher {
        display: none;
        @media screen and (max-width: 768px) {
          display: block;
        }
      }

      .products-back-buttons {
        .back-button {
          border: none !important;
          box-shadow: none !important;
        }
        li {
          display: flex;
          width: 100%;
          aspect-ratio: 150 / 46;

          a {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            aspect-ratio: 150 / 46;
            width: 100%;
            color: var(--default-color-2);
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.2em;
            padding: 0 30px;
            background-size: cover;

            &:hover {
              text-decoration: underline;
            }
          }

          &[data-back-product="TROUBADOUR"] {
            a {
              background-image: url(https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/troub-product-btn-bg.svg);
            }
          }
          &[data-back-product="FAMILY"] {
            a {
              background-image: url(https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/fam-product-btn-bg.svg);
            }
          }
          &[data-back-product="CONSTELLATION"] {
            a {
              background-image: url(https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/const-product-btn-bg.svg);
            }
          }
          &[data-back-product="NANOMONX"] {
            a {
              background-image: url(https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/admin-product-btn-bg.svg);
            }
          }
        }
      }

      // Styling specific for the admin/products logo tab
      div[data-link-id="products-link"] {
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 35px;
          border: 2px solid transparent;

          &:hover {
            border: 2px solid var(--line-separator);
          }
        }

        img {
          max-width: 100%;
          max-height: 130px;
        }

        @media all and (min-height: 640px) {
          img {
            max-height: 200px;
          }
        }
      }

      .language-button {
        border: none !important;
      }
    }

    &.self-promotion-header {
      width: calc(100% - 150px);
      margin-left: 150px;
    }

    .social-links {
      align-self: stretch;
      display: flex;
      flex-direction: row !important;
      justify-content: space-between;
      padding: 15px;

      .social-link {
        font-size: 24px;
        color: var(--nav-social-links);
      }
    }

    //Styling specific for link going from products to admin
    .admin-link {
      color: var(--admin-main);
    }

    .mobile-topnav {
      display: none;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
    }

    .mobile-menu-button {
      display: none;
    }

    .mobile-actions {
      display: none;
    }

    &[data-product="TROUBADOUR"],
    &[data-product="FAMILY"] {
      .login-button {
        background-color: var(--troubadour-main-17) !important;

        a {
          color: var(--troubadour-main-5) !important;
        }
      }

      .navigation-link {
        color: var(--troubadour-main);
      }

      &.top-menu {
        .top-menu-nav-links {
          a {
            color: var(--troubadour-main-5);
          }
        }

        .top-menu-dropdown {
          .dropdown-button {
            svg {
              color: var(--troubadour-main);
            }
          }
        }

        .nav-dropdown,
        .nav-button {
          border-right: 2px solid var(--troubadour-main-7);
        }
      }
    }
  }

  //Styling specific for each logged in products
  &[data-product="NANOMONX"] {
    .nanomonx-logo {
      display: block !important;
    }
    .mobile-menu-button {
      .icon {
        color: var(--admin-main);
      }
    }
  }

  &[data-product="TROUBADOUR"],
  &[data-product="FAMILY"],
  &[data-product="INFO"] {
    .troubadour-logo {
      display: block !important;
    }
    .mobile-menu-button {
      .icon {
        color: var(--troubadour-main);
      }
    }
  }

  &[data-product="CONSTELLATION"] {
    .constellation-logo {
      display: block !important;
    }
    .mobile-menu-button {
      .icon {
        color: var(--constellation-main);
      }
    }
  }

  //Adjust sidebar when not logged in, aligns to top;
  &:not(.connected) {
    .side-menu {
      .submenu-container {
        top: 100px;
        height: calc(100vh - 100px);
        border-top: none;
      }

      .nav-button {
        opacity: 1;
      }
    }
  }

  &.connected {
    .side-menu {
      .nav-button {
        opacity: 1;
      }
    }
  }

  &.troubleshooting-mode {
    .top-menu {
      background-color: var(--troubleshooting-mode);
    }
  }

  &.support-preview {
    position: relative;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    width: 200px;
    margin: 0 40% 60px 40%;
    z-index: 0;
  }

  @media screen and (max-width: 1200px) {
    .top-menu {
      .nav-button,
      .nav-dropdown {
        padding: 15px;

        &.login-button,
        &.logout-button {
          padding-right: 30px;
        }
      }

      .navigation-link {
        font-size: 18px;
      }

      .greeting,
      .greeting a {
        font-size: 18px;
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .top-menu:not(.top-menu--compact) {
      height: 80px !important;

      .greeting a {
        font-size: 16px;
        white-space: nowrap;
      }

      .logo-container {
        height: 80px !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    -webkit-backface-visibility: hidden;

    nav.top-menu:not(.top-menu--no-sidebar) {
      .action-section {
        display: none;
      }
    }

    .side-menu {
      .mobile-actions {
        display: flex;

        .login-button {
          width: 100% !important;
        }

        .student-name {
          color: var(--troubadour-main);
          padding: 20px;
          font-size: 18px;
          line-height: 15px;
          font-weight: 800;
          margin: 0;
          text-align: center;
          text-transform: none;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .nav-header {
          background-color: var(--troubadour-main-15);
        }
        .nav-button,
        .nav-header {
          border-bottom: 1px solid var(--top-nav-separator);

          &:last-of-type {
            border-bottom: none;
          }
        }
      }
    }

    .top-menu:not(.top-menu--compact) {
      display: flex !important;
      z-index: 10;
      height: 80px !important;

      .logo-container {
        display: flex;
        width: 100% !important;
        height: 80px !important;
        justify-content: space-between;
        align-items: center;

        .mobile-topnav {
          display: flex;
        }

        .mobile-menu-button {
          display: block;
          font-size: 28px;
          color: var(--default-color);
          cursor: pointer;
          margin-right: 25px;

          .icon {
            display: none;
          }

          &[data-state="closed"] {
            .icon.closed-icon {
              display: inline;
            }
          }

          &[data-state="opened"] {
            .icon.opened-icon {
              display: inline;
            }
          }
        }
      }
    }

    .top-menu {
      &.top-menu--compact {
        .logo-container {
          display: flex;
          width: 100% !important;
          justify-content: space-between;
          align-items: center;
          padding-left: 0;

          .nanomonx-logo {
            margin-left: 10px;
          }

          .mobile-topnav {
            display: flex;
          }

          .mobile-menu-button {
            display: block;
            font-size: 20px;
            color: var(--default-color);
            cursor: pointer;
            margin-right: 25px;

            .icon {
              display: none;
            }

            &[data-state="closed"] {
              .icon.closed-icon {
                display: inline;
              }
            }

            &[data-state="opened"] {
              .icon.opened-icon {
                display: inline;
              }
            }
          }
        }
      }

      &.top-menu--no-sidebar {
        .nav-button {
          &.language-button {
            padding: 0 5px 0 0;
          }
        }
      }
    }

    .navigation-link {
      font-size: 13px !important;
    }

    &[data-mobile-state="closed"] {
      .side-menu {
        display: none !important;
      }
    }

    &[data-mobile-state="opened"] {
      .side-menu {
        display: flex !important;
        align-self: flex-end;
        width: 170px;

        .logo-container {
          display: flex;
          align-items: center;
          justify-content: center;

          .nanomonx-logo {
            width: 100px;
          }
        }

        .submenu-container {
          top: 80px;
          left: initial;
          right: 170px;
          height: calc(100vh - 80px);
          width: 100px !important;
          z-index: 5;
          text-align: right;

          .submenu {
            width: 100% !important;

            .submenu-link {
              font-size: 12px !important;
              line-height: 14px !important;
              padding: 5px !important;
            }
          }

          .submenu-close-icon {
            align-self: flex-start;
            margin: 0 0 15px 15px;
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 769px) {
    .side-menu {
      &[data-sidenav-type="mobile"] {
        display: none;
      }
    }
  }

  // Compact header
  // Unique elements  + existing element overrides
  nav.top-menu--compact {
    padding: 0;

    .header-back {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      align-self: flex-start;
      height: 40px;

      &.header-back--demo {
        flex: 0;
      }
    }

    .header-page-indicators-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 40px;

      &.header-page-indicators-container--demo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      .header-page-number-indicators,
      .header-page-type-indicators {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .header-indicator {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        padding-right: 20px;

        tag.nano-badge {
          display: flex;
          border-radius: 50%;
          font-size: 16px;
          font-weight: 700;
          padding: 0;
          justify-content: center;
          color: var(--default-color-2);
          background-color: var(--troubadour-main);
        }

        tag.nano-badge,
        img {
          width: 24px;
          height: 24px;
          margin-left: 5px;
        }

        &.active {
          display: flex;
        }

        .header-indicator-label {
          font-size: 20px;
          font-weight: 800;
        }
      }
    }

    .action-section {
      height: 40px;

      .greeting-container {
        .freemium-mode,
        .greeting a {
          font-size: 16px;
          white-space: nowrap;
        }
      }

      .language-button,
      .login-button,
      .logout-button {
        .navigation-link {
          font-size: 16px;
          white-space: nowrap;
        }
      }

      .language-button {
        .navigation-link {
          img {
            width: 25px;
          }
        }
      }

      .login-button,
      .logout-button {
        .navigation-link {
          img {
            width: 16px;
          }
        }
      }
    }

    @media (min-width: 1025px) {
      .header-page-indicators-container {
        .header-indicator {
          tag.nano-badge {
            font-size: 20px;
          }

          tag.nano-badge,
          img {
            width: 32px;
            height: 32px;
          }

          .header-indicator-label {
            font-size: 27px;
          }
        }
      }
    }
  }

  // THEMES
  &[data-theme="black"] {
    nav {
      background-color: var(--default-color-3);

      .logo-container {
        .nanomonx-presents-heading {
          color: var(--default-color-2);
        }
      }

      .navigation-link {
        color: var(--default-color-2);

        img {
          filter: grayscale();
        }
      }
    }
  }

  &[data-theme="teal"] {
    nav {
      background-color: var(--tutorial-color-9);

      .logo-container {
        .nanomonx-presents-heading {
          color: var(--default-color-2);
        }
      }

      .navigation-link {
        color: var(--constellation-main-5);

        img {
          filter: grayscale();
        }
      }
    }
  }
}
