.nanopopup-backpanel {
  display: none;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.45);
  z-index: 320;
  transition: opacity 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);

  &.display {
    display: block;
    position: fixed;
    opacity: 1;
  }

  &.transparent {
    background: transparent;
  }
}

.nanopopup {
  .nanopopup-template(var(--popup-default));
}

.nanopopup-important {
  .nanopopup-template(var(--popup-error));
}

.nanopopup-success {
  .nanopopup-template(var(--popup-success));
}

.nanopopup-warning {
  .nanopopup-template(var(--popup-warning));
}

.nanopopup-current {
  .nanopopup-template(var(--button-uc-current));
}

.nanopopup-resend {
  .nanopopup-template(var(--button-uc-resend));
}

.nanopopup-done {
  .nanopopup-template(var(--button-uc-done));
}

.nanopopup-large {
  max-width: 1280px !important;
  min-width: 950px !important;
}

.nanopopup-scroll {
  overflow: hidden !important;

  .scrollable {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 40vh;
    margin-bottom: 20px;
    padding-bottom: 20px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--primary-text-1);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--default-bg);
      border-radius: 5px;
    }

    @supports not selector(::-webkit-scrollbar) {
      scrollbar-color: var(--primary-text-1) var(--default-bg);
      scrollbar-width: thin;
    }
  }
}

.nanopopup-template(@color) {
  display: none;
  padding: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  opacity: 0;
  z-index: 325;
  width: 80%;
  max-width: 1000px;
  min-width: 50vw;
  max-height: calc(100vh - 50px);
  transform: translate(-50%, -50%);
  background-color: var(--default-bg);
  border-radius: 21px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transition: opacity 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow-y: auto;

  &.inactivity-popup {
    z-index: 350;
  }

  &.display {
    display: block;
    position: fixed;
    opacity: 1;
  }

  .close-button {
    color: @color;
  }

  .video-row {
    padding: 0 5%;

    .video-container {
      position: relative;
      padding-bottom: 56.25%; //Keeps aspect ratio to 16:9
      overflow: hidden;
      width: 100%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 21px;
        border: 5px solid var(--border);
      }
    }
  }

  &.reading-challenge-edit {
    padding: 0 !important;
    width: 70%;
    max-width: 70%;
    max-height: 95%;

    .input-popup {
      padding: 0 50px;
      overflow-y: scroll;
      max-height: 40vh;

      & > * {
        margin-bottom: 15px;
      }
    }

    .button-group {
      padding: 0 50px 62px 50px !important;
    }

    label {
      font-family: Lato, sans-serif;
      color: var(--default-color);
      font-weight: normal;
      font-size: 15px;
      padding: 0 0 9px 0 !important;
      margin: 0 !important;
      font-style: normal;

      & .not-editable-text {
        margin-left: 5px;
        font-size: 10px;
        opacity: 0.6;
        font-weight: bolder;
      }
    }

    .input-popup input,
    textarea {
      font-size: 13px !important;
      line-height: 1.42857143 !important;
      color: #333 !important;
      text-align: left !important;
      font-style: normal;
    }

    .editable-text {
      border: 2px solid var(--primary-text-1) !important;
      font-weight: bolder;
      opacity: 1;
    }
  }

  &.no-event {
    pointer-events: none;
  }

  .display-image {
    overflow: hidden;
    object-fit: cover;
    object-position: center;
    width: 100%;
    max-height: 200px;
    border-radius: 21px 21px 0 0;
  }

  .close-container {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    right: 15px;
    top: 15px;
    cursor: pointer;

    &.display-left {
      right: auto;
      left: 15px;
    }

    i[data-fa-i2svg] {
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      font-size: 20px;
      margin: 0;

      &:hover {
        color: var(--default-color);
      }

      &:active {
        opacity: 0.4;
      }
    }
  }

  .inline-button {
    background: @color;
    color: var(--default-color-2) !important;
    padding: 12px !important;
    border-radius: 6px;
    width: 80%;
    display: block;
    margin-top: 15px;
    margin-left: 10%;
    text-align: center;
  }

  .title {
    text-align: center;
    font-weight: 900;
    font-size: clamp(24px, 3vw, 42px);
    color: @color;
  }

  .subtitle {
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    color: @color;
  }

  .main-text {
    color: var(--default-color-8);
    text-align: left;
    padding: 5px 20px;
    font-weight: 400;
    font-size: 17px;

    a {
      color: var(--primary-text-1);
      opacity: 1 !important;
    }
  }

  .text-justify {
    text-align: justify !important;
  }

  .error-section {
    display: flex;
    justify-content: center;
  }

  .second-text {
    color: var(--default-color);
    text-align: left;
    padding: 2px 20px;
    font-weight: 700;
    font-size: 16px;
  }

  a {
    text-decoration: underline;
    font-weight: bolder;
    opacity: 0.9;
    color: @color;

    &:hover {
      opacity: 1;
      text-decoration: none;
    }

    &.troubadour-button {
      text-decoration: none;
    }
  }

  .input-popup {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 15px;
    flex-direction: column;

    label {
      text-align: center;
    }

    a {
      text-align: left !important;
      padding: 15px 10px 10px 10px;
      color: @color;
      cursor: pointer;
      transition: all 0.25s cubic-bezier(0.25, 0.8, 0.25, 1);
      text-decoration: underline;
      font-weight: bolder;
      opacity: 0.9;

      &:hover {
        opacity: 1;
        text-decoration: none;
      }
    }

    input:not([type="checkbox"]) {
      text-align: center;
      border-radius: 6px;
      padding: 10px;
      border: 1px solid @color;
      font-size: 18px;

      color: @color;
      transition: all 0.25s cubic-bezier(0.25, 0.8, 0.25, 1);

      &::placeholder {
        opacity: 0.5;
        color: @color;
      }

      &:focus {
        &::placeholder {
          opacity: 0.2;
        }
      }
    }

    input[type="checkbox"] {
      border-radius: 4px;
      padding: 9px;
      margin: 12px 0;
      border: 2px solid @color;
      color: @color !important;
      float: left;
      width: 12px;

      &:checked {
        background: @color;
      }
    }

    label {
      padding: 9px;
      margin-left: 15px;
    }

    p {
      margin: 0;
      float: left;
      padding: 12px;
      font-size: 16px;
      color: @color !important;
    }

    .clipboard-input {
      margin: 5px 0 10px 0;
      background: var(--default-bg);
      border: 1px solid var(--border);
      padding: 21px;
      border-radius: 6px;
      transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      cursor: pointer;
      text-align: center;

      p {
        text-transform: uppercase;
        margin: 0;
        padding: 0;
        color: var(--default-color);
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 10px;
        width: 100%;
        opacity: 0.8;
      }
    }

    .clipboard-input:hover {
      background: var(--default-bg);
    }

    .clipboard-input:active {
      background: var(--default-bg);
    }
  }

  .nanopopup-button() {
    margin: 0 15px;
    font-size: 18px;
    font-weight: 700;
    padding: 16px;
    border-radius: 10px;
    min-width: 300px;
    position: relative;
    transition:
      transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1),
      opacity 0.3s cubic-bezier(0.25, 0.8, 0.25, 1),
      background-color 0.24s cubic-bezier(0.25, 0.8, 0.25, 1);

    opacity: 1;

    .inner-icon {
      font-size: 18px;
      opacity: 0;
      position: relative;
    }

    &:hover {
      opacity: 0.5;
    }

    &:focus {
      outline: none !important;
    }

    &:active {
      opacity: 0.6 !important;
    }

    &.disabled {
      background-color: var(--disabled) !important;
      color: var(--default-color-2) !important;
      pointer-events: none;
    }
  }

  .button-group {
    display: flex;
    justify-content: center;
    align-items: center;

    .button-absolute {
      position: absolute !important;
    }

    .button-error {
      background-color: var(--content-invalid) !important;
    }

    .button-success {
      background-color: var(--valid) !important;
    }

    .button-disable {
      pointer-events: none !important;
    }

    .action-button:not(.troubadour-button) {
      .nanopopup-button();
      background: @color;
      border: 2px solid rgba(0, 0, 0, 0);
      color: var(--default-color-2);

      &:hover {
        color: @color !important;
        opacity: 1;
      }
    }

    .cancel-button {
      .nanopopup-button();
      background: none;
      border: 2px solid @color;
      color: @color;

      &:hover {
        color: @color !important;
      }
    }
  }

  // Overrides when the nanopopup should
  // have a Troubadour colour palette
  &[data-theme="troubadour"] {
    background-color: var(--troubadour-main-15);

    .close-container {
      i,
      svg {
        color: var(--troubadour-main-5);
      }
    }

    .inline-button {
      background: var(--troubadour-main-5);
    }

    .title {
      color: var(--troubadour-main);
    }

    .subtitle {
      color: var(--troubadour-main);
    }

    .main-text {
      a {
        color: var(--troubadour-main-5);
      }
    }

    a {
      color: var(--troubadour-main-5);
    }
  }
}

@media (max-width: 1280px) {
  .nanopopup {
    max-width: 80%;
    min-width: 80%;
  }

  .nanopopup-important {
    max-width: 80%;
    min-width: 80%;
  }

  .nanopopup {
    max-width: 80%;
    min-width: 80%;
  }

  .nanopopup-success {
    max-width: 80%;
    min-width: 80%;
  }

  .nanopopup-warning {
    max-width: 80%;
    min-width: 80%;
  }

  .nanopopup-current {
    max-width: 80%;
    min-width: 80%;
  }

  .nanopopup-done {
    max-width: 80%;
    min-width: 80%;
  }
}

@media (max-width: 992px) {
  .nanopopup,
  .nanopopup-large,
  .nanopopup-important,
  .nanopopup-success,
  .nanopopup-warning,
  .nanopopup-current,
  .nanopopup-done {
    max-width: 80% !important;
    min-width: 80% !important;
    padding: 22px 20px 32px 20px !important;

    .button-group {
      flex-wrap: wrap;
    }
  }

  .nanopopup-done {
    .general-comment-container {
      .general-comment {
        height: 100px !important;
      }
    }

    .general-history-container {
      .general-history-panel {
        height: 100px !important;
      }
    }
  }

  #tutorial-popup-skip,
  #tutorial-popup-close {
    display: none;
  }

  #tutorial-popup {
    display: none !important;

    & + .nanopopup-backpanel {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .nanopopup-large,
  .nanopopup-important,
  .nanopopup-success,
  .nanopopup-warning,
  .nanopopup {
    max-width: 80% !important;
    min-width: 80% !important;
    max-height: 80vh !important;
    overflow-y: scroll;
  }
}

@media (max-width: 480px) {
  .nanopopup {
    height: 90vh !important;
    max-height: unset !important;
    min-width: 90% !important;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px;

    .title {
      margin-top: 35px;
      font-size: 2em;
    }

    .main-text {
      font-size: 1.1em;
    }

    .second-text {
      font-size: 1.1em;
    }

    .button-group {
      flex-direction: column;

      .action-button,
      .cancel-button {
        min-width: 60%;
        max-width: 90%;
        margin-top: 5px;
      }
    }

    .close-container {
      right: 5px;
      top: 5px;

      &.display-left {
        right: auto;
        left: 5px;
      }
    }
  }
}
