.closed:not(a) > span {
  background-color: var(--default-bg) !important;
}

.left-menu {
  position: fixed;
  height: calc(100vh - 40px);
  top: 41px;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--default-bg);
  min-width: 150px;
  pointer-events: initial;
  flex: 1;
  box-shadow: var(--side-menu-dropshadow);

  .scroll-group {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    margin: 15px 0 5px 0;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--scrollbar);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--default-bg);
      border-radius: 5px;
    }

    @supports not selector(::-webkit-scrollbar) {
      scrollbar-color: var(--scrollbar) var(--default-bg);
      scrollbar-width: thin;
    }
  }

  hr {
    margin: 6px 0;
    width: 100%;
  }

  .left-menu-buttons-group {
    text-decoration: none;
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    border: none;
    margin-top: 0;
    margin-bottom: 0;

    h3 {
      margin: 20px 0 0 0;
      font-size: 18px;
    }

    p {
      margin: 0;
    }

    &:first-child {
      a:first-child {
        margin-top: 0;
      }
    }
  }

  .left-menu-buttons-group:nth-child(2) {
    padding-bottom: 5px;
    margin-bottom: 5px;
  }

  a {
    margin: 2px 8px;
    align-items: center;
    text-decoration: none;
    color: var(--left-menu-color);
    opacity: 0.8;
    font-weight: normal !important;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 8px;
    border-radius: 6px;
    transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover:not(.active-nav):not(.left-menu-header) {
      color: var(--left-menu-hover) !important;
      cursor: pointer;
      background: var(--left-menu-hover-bg);

      i[data-fa-i2svg] {
        transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
        color: var(--left-menu-hover) !important;
      }
    }

    &:hover {
      cursor: pointer;
    }

    i[data-fa-i2svg] {
      font-size: 15px;
      margin: 0 10px 0 -8px;
      max-width: 25px;
      min-width: 25px;
      padding: 5px 0;
      text-align: center;
    }

    i[data-fa-i2svg] + p {
      display: inline;
      font-size: 15px;
      min-width: 180px;
      line-height: 29px;

      &:not(:first-child) {
        margin: 0;
      }
    }

    span {
      display: flex;
      align-items: baseline;
      margin: 0 8px;
    }
  }

  .active-nav {
    background: var(--left-menu-selected-bg);
    border-radius: 6px;
    font-size: 16px;
    color: var(--primary-text-1);

    i {
      color: var(--primary-text-1);
      vertical-align: sub;
    }
  }

  i {
    color: var(--default-color);
    opacity: 0.8;
    cursor: pointer;
  }

  &:last-child {
    margin-bottom: 20px;
  }

  &.closed {
    width: 67px;
    min-width: 67px;

    span {
      margin: auto !important;
    }

    i[data-fa-i2svg] + p {
      font-size: 0;
    }

    .arrow-fa svg {
      transform: rotate(180deg);
    }

    .arrow-div {
      text-align: center !important;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
      float: right;
    }

    .arrow-div:hover {
      transform: translateX(5px) !important;
    }

    a i + p {
      opacity: 0;
      min-width: 0;
      max-width: 0;
      white-space: nowrap;
    }

    i[data-fa-i2svg] {
      margin: auto;
    }
  }

  .left-menu--close {
    height: 100%;
  }

  .arrow-container {
    padding-top: 30px;
    padding-bottom: 95px;
    cursor: pointer;
    border-top: 1px solid var(--border);

    .arrow-div {
      text-align: right;
      padding: 0 18px;
      width: 100%;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    .arrow-div:hover {
      transform: translateX(-5px);
    }

    .arrow-fa {
      padding: 0;

      margin: 0;
      font-size: 22px;
      color: var(--primary-text-1);
    }
  }

  &.troubleshooting-mode {
    max-width: 150px;

    .left-menu-buttons-group {
      h3 {
        margin: 20px 0 0 0;
        padding: 0 5px;
      }
    }

    a {
      display: flex;
      flex-flow: row nowrap;
      align-items: baseline;
      width: 150px;
      margin: 0;
      padding: 5px;
      opacity: 0.8;
      font-weight: normal !important;
      gap: 5px;

      i {
        margin: 0;
        padding: 0;
      }

      span {
        display: inline;
        font-size: 14px;
        line-height: 1.1em;
        margin: 0;
      }
    }
  }
}

.burger-open {
  display: none;
}

.burger-close {
  display: none;
}

.blur {
  filter: grayscale(100%);
}

.visible {
  display: block;
}

.absolute {
  position: absolute;
}

@media (max-width: 992px) {
  .left-menu {
    min-width: 150px;

    a {
      i[data-fa-i2svg] + p {
        font-size: 14px;
        min-width: 150px;
      }
    }

    .scroll-group {
      height: initial;
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }
}

@media (max-width: 767px) {
  .arrow-container {
    display: none;
  }
}
