@import "Inputs";
@import "TopSectionTitle";

.border-class {
  border: 2px solid var(--primary-text-1);
}

.form-panel {
  padding: 60px 0 60px 0;
  min-width: 100%;
  max-width: none;
  margin: 0 -15px 50px -15px;
  border-radius: 10px;
  border: 2px solid var(--default-color-5);
  background-color: var(--default-bg);

  hr {
    margin: 20px 0 35px;
  }

  .section-title {
    padding: 0 42px;
    font-size: 18px;
    font-weight: bold;
  }

  form {
    width: 80%;
    padding: 0;
    margin: 0 auto;
  }

  .row {
    line-height: 22px;
    margin-bottom: 15px;
  }

  .row-flex {
    display: flex;

    div {
      margin: auto;
    }

    input {
      margin: 0 10px;
    }
  }

  i.fa {
    font-size: 16px;
    margin: 0 0 0 10px;
  }

  .sub-title {
    width: 80%;
    font-size: 20px;
    padding: 0;
    margin: 0 auto;
    opacity: 0.8;
  }

  .inline-tooltip {
    font-size: 12px;
    opacity: 0.8;
    font-weight: bold;
  }

  .section {
    font-size: 22px;
    padding: 0;
    margin: 20px auto auto auto;
    opacity: 0.7;
  }

  .name-field {
    padding-bottom: 25px;
  }

  .know-more-field {
    display: flex;
    padding-top: 25px;
    padding-bottom: 15px;
    align-items: center;
  }

  .tooltip .tooltip-inner {
    max-width: 400px !important;
    border-radius: 6px !important;
    white-space: normal;
    font-size: 13px;
    padding: 15px !important;
  }

  label {
    color: var(--default-color);
    font-weight: normal;
    font-size: 15px;
    margin: 0 0 10px 0;
    font-style: normal;
  }

  select {
    .border-class();
    width: 100% !important;
    color: var(--primary-text-1);
  }

  .row-disabled {
    pointer-events: none;
  }

  .multi-checkbox-element {
    .all-themes {
      padding-bottom: 15px;

      & .all-themes-icon {
        font-size: 1.35em;
        margin: 0 3px 0 -8px;

        &.fa-toggle-on {
          color: var(--valid);
        }

        &.fa-toggle-off {
          color: var(--disabled);
        }
      }
    }

    .deselect-all-themes {
      padding-bottom: 25px;
    }

    .inner {
      float: left;
      display: flex;
      align-items: center;
    }

    i {
      font-size: 19px;
      position: absolute;
      left: -10px;
    }
  }

  .valid {
    background-color: var(--content-valid);
  }

  .invalid {
    background-color: var(--content-invalid);
  }

  .error-text {
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

    padding: 10px 0 0 0;
    color: var(--content-invalid) !important;
  }

  .color--confirmed {
    color: var(--content-valid) !important;
  }

  .color--error {
    color: var(--content-invalid) !important;
  }

  .color--default {
    color: var(--default-color) !important;
  }

  .border--error {
    border: 2px solid var(--content-invalid) !important;
  }

  .border--confirmed {
    border: 2px solid var(--content-valid) !important;
  }

  .border--default {
    border: 2px solid var(--primary-text-1) !important;
  }
}
