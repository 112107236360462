.tooltip-wrapper {
  .tooltip {
    background: var(--primary-text-1);
    bottom: 100%;
    color: var(--default-color-2);
    display: block;
    margin-bottom: 15px;
    .opacity(0);
    padding: 20px;
    pointer-events: none;
    position: absolute;
    width: 200px;
    .transform(translate(-50%, 10px));
    .transition(all 0.25s ease-out);
    .box-shadow(2px 2px 6px rgba(0, 0, 0, 0.28));
  }

  /* This bridges the gap so you can mouse into the tooltip without it disappearing */

  .tooltip:before {
    bottom: -20px;
    content: " ";
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  /* CSS Triangles - see Trevor's post */

  .tooltip:after {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid var(--primary-text-1) 10px;
    bottom: -10px;
    content: " ";
    height: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    width: 0;
  }

  &:hover .tooltip {
    .opacity(1);
    pointer-events: auto;
    .transform(translateX(-50%));

    &.centered {
      .transform(translateX(-25%));
    }
  }
}

.question-mark {
  text-transform: uppercase;
  color: var(--default-color);
  cursor: help;
  font-family:
    Gill Sans,
    Impact,
    sans-serif;
  font-size: 20px;
  padding: 15px 15px;
  position: absolute;
  text-align: center;
  width: 10px;
  margin-left: -33px;
  margin-top: -8px;
  .transform(translateZ(0));
  -webkit-font-smoothing: antialiased;

  i {
    position: absolute;
    margin-left: -11px;
    margin-top: -9px;
    color: var(--default-color);
  }
}

.tooltipClick {
  .opacity(1) !important;
  pointer-events: auto !important;
  .transform(translateY(0px)) !important;
}

/* IE can just show/hide with no transition */
.lte8 .wrapper .tooltip {
  display: none;
}

.lte8 .wrapper:hover .tooltip {
  display: block;
}

// Tippy tooltip container
div[data-tippy-root] {
  width: auto;
  max-width: 300px;
}

.tippy-box-tmpl() {
  font-size: 16px;
  font-weight: 400;
  padding: 15px;
  border-radius: 6px;
  max-width: 100px;
  box-shadow: var(--dropshadow);
  overflow-wrap: break-word;

  .tippy-arrow {
    border: solid transparent 8px;
    position: absolute;
  }
}

.tippy-box[data-theme~="nanomonx"] {
  .tippy-box-tmpl();
  color: var(--default-color-2);
  background-color: var(--primary-text-1);
  &[data-placement^="top"] {
    .tippy-arrow {
      border-top-color: var(--primary-text-1);
      top: 100%;
    }
  }

  &[data-placement^="bottom"] {
    .tippy-arrow {
      border-bottom-color: var(--primary-text-1);
      bottom: 100%;
    }
  }

  &[data-placement^="left"] {
    .tippy-arrow {
      border-left-color: var(--primary-text-1);
      left: 100%;
    }
  }

  &[data-placement^="right"] {
    .tippy-arrow {
      border-right-color: var(--primary-text-1);
      right: 100%;
    }
  }
}

.tippy-box[data-theme~="troubadour"] {
  .tippy-box-tmpl();
  color: var(--default-color);
  background-color: var(--tooltip-troub-dark-bg);

  &[data-placement^="top"] {
    .tippy-arrow {
      border-top-color: var(--tooltip-troub-dark-bg);
      top: 100%;
    }
  }

  &[data-placement^="bottom"] {
    .tippy-arrow {
      border-bottom-color: var(--tooltip-troub-dark-bg);
      bottom: 100%;
    }
  }

  &[data-placement^="left"] {
    .tippy-arrow {
      border-left-color: var(--tooltip-troub-dark-bg);
      left: 100%;
    }
  }

  &[data-placement^="right"] {
    .tippy-arrow {
      border-right-color: var(--tooltip-troub-dark-bg);
      right: 100%;
    }
  }
}

[data-toggle="tooltip"] {
  cursor: pointer;
}

@media only screen and (max-width: 992px) {
  .wrapper {
    display: none;
  }
}
