@import "../../../../resources/less/components/NanoPopup.less";

.nanopopup.nanopopup-freemium {
  .nanopopup-template(var(--troubadour-main));
  padding: 40px 60px !important;
  background-color: var(--default-bg-2);
  min-width: 300px !important;
  max-width: 560px !important;
  overflow: visible;

  .close-container {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--default-bg);
    left: 15px;
    top: 15px;
    box-shadow: var(--dropshadow);

    i,
    svg {
      font-size: 20px;
      color: var(--troubadour-main-12);
    }

    &:hover {
      i,
      svg {
        color: var(--default-bg);
      }

      background-color: var(--troubadour-main-12);
    }
  }

  &::after {
    content: "";
    position: absolute;
    right: 40px;
    top: -30px;
    aspect-ratio: 78 / 114;
    width: 78px;
    background-image: url("https://constellation-backend-images.s3.ca-central-1.amazonaws.com/production/interfaces/troub-paid-package-ribbon.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  h2 {
    color: var(--troubadour-main);
    font-size: clamp(30px, 4vw, 40px);
    font-weight: 800;
    line-height: 1.1em;
    text-transform: none;
    text-align: left;
    margin: 0 0 30px;

    &.small-title {
      font-size: 23px;
      line-height: 1.2em;
    }
  }

  p {
    color: var(--default-color-4);
    font-size: 23px;
    line-height: 1em;
    margin: 0 0 30px;

    strong {
      font-weight: 800;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.small-text {
      font-size: 18px;
    }
  }

  .scrollable-content {
    padding: 0 10px 0 0;
    height: 50vh;
    max-height: 250px !important;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--troubadour-main);
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--default-bg-2);
      border-radius: 5px;
    }

    @supports not selector(::-webkit-scrollbar) {
      scrollbar-color: var(--troubadour-main) var(--default-bg-2);
      scrollbar-width: thin;
    }
  }

  .button-group {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
    margin: 30px 0 0;
    padding: 0;
    gap: 20px;
  }

  &[data-popup-size="large"] {
    max-width: 800px !important;

    .button-group {
      justify-content: center;
    }
  }
}
