.top-section-title {
  margin-bottom: 25px;
  border-radius: 12px;
  display: flex !important;
  justify-content: left;
  align-items: center;
  border: 2px solid var(--default-color-5);
  background-color: var(--default-bg);
  padding: 20px 25px;

  .flex-end,
  .flex-single {
    display: flex;
    margin-left: auto;
    justify-content: flex-end;

    .dropdown-menu {
      margin: auto;
    }

    button:not(.troubadour-button) {
      align-items: center;
      margin: auto;
      line-height: normal;
    }
  }

  .flex-section {
    display: flex !important;
  }

  .flex {
    display: flex;
  }

  .group-button {
    width: 100%;
  }

  .flex-group {
    flex-grow: 3;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  h2,
  h4 {
    svg {
      display: flex;
      align-items: center;
      font-size: 19px;
      padding-left: 5px;
      margin-right: 10px;
    }
  }

  i {
    display: flex;
    align-items: center;
    font-size: 19px;
    padding-left: 5px;
    margin-right: 10px;
  }

  img {
    height: 20px;
    margin-right: 10px;
  }

  a {
    margin: 0 5px;
  }

  button:not(.troubadour-button) {
    border: 2px solid rgba(255, 255, 255, 0);
    float: right;
    margin: 0 auto;
    font-weight: 600;
    text-decoration: none;
    border-radius: 6px;
    background-color: var(--primary-text-1);

    font-size: 15px;
    color: var(--default-color-2);
    transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);

    img {
      filter: invert(100%) sepia(100%) saturate(16%) hue-rotate(234deg)
        brightness(106%) contrast(104%);
      height: 18px;
    }

    &:hover {
      background-color: var(--primary-text-2-dark);
    }
  }

  .action-button(@color1) {
    background-color: @color1;
    margin-left: 10px;
    display: flex;

    .button-subtext {
      display: inline;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .delete-button,
  .reject-button {
    .action-button(var(--content-invalid));
  }

  button:hover {
    background-color: var(--primary-text-2-dark);
  }

  .student-overflow {
    color: var(--content-invalid);
    opacity: 1 !important;
  }

  .text-element {
    align-content: center;
    padding-right: 5px;
    font-size: clamp(15px, 2vw, 19px);
    display: inline-flex;
    align-items: center;
    margin: 0;
    font-weight: bold;
    line-height: 1;
    color: var(--default-color);

    &:first-letter {
      text-transform: uppercase;
    }

    span {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .group-name {
    color: var(--primary-text-1);
    font-size: 19px;
    font-weight: 900;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & + .visible-challenge-counter {
      color: var(--primary-text-1);
      font-size: 12px;
      text-align: left;
    }
  }

  .filters-wrap {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;

    .filters-reset {
      margin-top: 15px !important;
    }

    @media (min-width: 601px) {
      flex-direction: row;
      flex-basis: auto;

      .filters-reset {
        margin-top: auto !important;
      }
    }

    @media (min-width: 990px) {
      flex-wrap: nowrap;
    }
  }

  .filter-block {
    display: flex;
    padding: 5px 0 5px 10px;
    flex-direction: column;
    align-self: center;

    @media (min-width: 769px) {
      flex-direction: column;
    }

    .title {
      font-size: 12px;
      font-weight: bolder;
      opacity: 0.6;
      margin: auto 10px auto 10px;
    }

    .content {
      margin: auto;

      button {
        margin: unset;
        min-width: 100px;
      }
    }
  }

  @media (max-width: 1200px) {
    font-size: 12px;
    .group-name {
      font-size: 16px;

      & + .visible-challenge-counter {
        font-size: 10px;
      }
    }

    .flex-end,
    .flex-single {
      button {
        font-size: 12px;

        i {
          margin: auto 0 auto 10px;
        }
      }
    }
  }

  @media (max-width: 992px) {
    flex-wrap: wrap;
    &:first-of-type {
      padding-top: 25px;
    }

    .title-group {
      padding-bottom: 15px;
    }

    .flex-end {
      margin-left: 0 !important;
      justify-content: flex-start;
      padding: 15px 0;

      button:not(.troubadour-button) {
        font-size: 12px;
      }
    }

    .filter-block {
      padding: 5px;
      justify-content: center;
      align-items: center;

      .title {
        font-size: 12px;
        padding-left: 0;
        margin: 0 10px 0 0;
      }

      .content {
        margin: 0;
      }
    }
  }
}
