

.video-tutorial-button {
  a {
    text-transform: initial; // Label gets capitalized is not set to initial
    animation: animatedGlow 1.5s alternate infinite ease-in-out;

    i {
      margin-right: 10px;
    }
  }
}

.video-tutorial {
  display: none;
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--backdrop);
  }

  &.show {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .backdrop {
      animation: fade-in 0.25s forwards;
    }
  }

  .outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 90%;

    .video-container {
      flex: 0 0 60%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .video-nav {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 8em;
        cursor: pointer;
        color: var(--default-color-2);
        z-index: 300;
        width: 15%;

        &:hover {
          color: var(--primary-text-1);
        }

        &:active {
          color: var(--default-color-2);
        }
      }

      .video-main {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .video-topbar {
          padding-bottom: 15px;
          display: flex;
          justify-content: space-between;
          z-index: 300;

          .video-title {
            color: var(--default-color-2);
            text-transform: uppercase;
          }

          .close-button {
            cursor: pointer;
            align-self: flex-end;
            padding-right: 1em;
            color: var(--default-color-2);
            font-size: 2em;
          }
        }

        .video-player {
          position: relative;
          padding-bottom: 56.25%; //Keeps aspect ratio to 16:9

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 21px;
            border: 5px solid var(--border);
          }
        }
      }
    }

    .video-list {
      flex: 0 0 auto;
      width: 100%;
      z-index: 300;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .video-list-button {
        min-height: 4em;
        flex: 0 0 22%;
        margin: 1em;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1em;
      }
    }
  }
}

@keyframes animatedGlow {
  from {
    box-shadow: var(--primary-text-2-dark) 0 0 5px 5px;
  }

  to {
    box-shadow: var(--primary-text-2) 0 0 5px 5px;
  }
}

@keyframes fade-in {
  from {
    background-color: transparent;
  }

  to {
    background-color: var(--backdrop);
  }
}

@media (max-width: 1366px) {
  .video-tutorial-button {
    a {
      svg {
        margin: auto !important;
      }

      div {
        display: none;
      }
    }
  }
}

@media (max-width: 767px) {
  .video-tutorial-button {
    display: none;
  }
}
