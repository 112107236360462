@media only screen and (max-width: 992px) {
  .sm-hide {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .xs-hide {
    display: none;
  }
}
