// We want a consistent UI for all buttons which link TO pages on Admin. This way a user
// knows when they click on this link, it will take them out of whichever app they're in
// and to Admin. This component contains the style for that UI.

.admin-link-button {
  background-color: var(--admin-main);
}

.admin-button-group {
  background-color: var(--admin-main);
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 5px;

  a {
    margin: 0 10px;
  }

  img.nanomonx-icon {
    width: 50px;
    height: auto;
    margin-right: 10px;
  }

  &:hover {
    cursor: initial;
  }
}