.phl-width--mini {
  width: 7% !important;
}

.phl-width--tiny {
  width: 15% !important;
}

.phl-width--small {
  width: 30% !important;
}

.phl-width--medium {
  width: 45% !important;
}

.phl-width--normal {
  width: 60% !important;
}

.phl-width--large {
  width: 75% !important;
}

.phl-width--larger {
  width: 90% !important;
}

.phl-width--big {
  width: 100% !important;
}

.ph1-width--giant {
  width: 150% !important;
}

.phl-height--tiny {
  height: 15px !important;
}

.phl-height--small {
  height: 30px !important;
}

.phl-height--medium {
  height: 45px !important;
}

.phl-height--normal {
  height: 60px !important;
}

.phl-height--large {
  height: 75px !important;
}

.phl-height--larger {
  height: 90px !important;
}

.ph1-height--tall {
  height: 90% !important;
}

.phl-height--big {
  height: 115px !important;
}

.phl-height--biggest {
  height: 215px !important;
}

.phl-float {
  margin-right: 15px !important;
  float: left;
}

.phl-push--top {
  margin-top: 50px !important;
}

.phl-push--top-15 {
  margin-top: 15px !important;
}

.phl-push--bottom-15 {
  margin-top: 15px !important;
}

.phl-push--bottom {
  margin-bottom: 50px !important;
}

.phl-push-bottom-15 {
  margin-bottom: 15px !important;
}

.phl-push-right-15 {
  margin-right: 15px !important;
}

.phl-absolute {
  position: absolute !important;
}

.placeholder-loading--animation() {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: var(--default-bg);
  background: linear-gradient(to right, #f7f7f7 2%, #eaeaea 18%, #f7f7f7 33%);
  background-size: 1000px 104px;
  overflow: hidden;
}

.placeholder-loading--line {
  .placeholder-loading--animation();
  border-radius: 2px;
  position: relative;
  z-index: 1;
  margin-top: 0;
  margin-bottom: 15px;
}

.placeholder-loading--rectangle {
  .placeholder-loading--animation();
  border-radius: 2px;
  position: relative;
  z-index: 1;
  margin: 50px;
  height: 100px;
  width: 150px;
}

.placeholder-loading--square {
  .placeholder-loading--animation();
  border-radius: 2px;
  position: relative;
  z-index: 1;
  margin: 0 15px;
  height: 150px;
  width: 150px;
}

.hideElement {
  display: none;
  list-style-type: none;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
